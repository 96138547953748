<template>
    <div>
        <h2>Export</h2>
        <form v-on:submit.prevent="runExport()">
            <table style="text-align:center;margin-left:auto;margin-right:auto;border-spacing:30px;border-collapse:separate">
                <td>
                    <p>Export Mode</p>
                    <input type="radio" id="mf_exp" name="export_type" v-model="mf_selected" :value="true">
                    <label for="mf_exp"> Male/Female</label>
                    <br>
                    <!-- <input type="radio" id="mf_hist_exp" name="export_type" v-model="mf_selected" :value="true">
                    <label for="mf_hist_exp"> Male/Female Historical (Runs a normal export + OG Keypoint)</label>
                    <br> -->
                    <input type="radio" id="sv_exp" name="export_type" v-model="mf_selected" :value="false">
                    <label for="sv_exp"> Single Variety</label>
                    <br>
                </td>
                <td>
                    <p>Units</p> 
                    <input type="radio" id="metric" name="units">
                    <label for="metric"> Metric</label>
                    <br>
                    <input type="radio" id="imperial" name="units">
                    <label for="imperial"> Imperial</label>
                    <br>
                </td>
                <td>
                    <p>Rowfill</p>
                    <input type="checkbox" id="rowfill" name="rowfill" v-if="!mf_selected">
                    <label for="rowfill" v-if="!mf_selected"> Rowfill</label>

                    <input type="checkbox" id="rowfill_male" name="rowfill_male" v-if="mf_selected">
                    <label for="rowfill_male" v-if="mf_selected"> Rowfill Male</label>
                    <br>
                    <input type="checkbox" id="rowfill_female" name="rowfill_female" v-if="mf_selected">
                    <label for="rowfill_female" v-if="mf_selected"> Rowfill Female</label>
                    <br> 
                </td>
                <td>
                    <p>Upload</p>
                    <input type="checkbox" id="upload" name="upload" v-model="upload_flag" :value="true">
                    <label for="upload"> Upload to FieldAgent</label>

                    <input type="checkbox" id="release" name="release" :disabled="!upload_flag">
                    <label for="release"> Upload as Released</label>
                </td>
                <td>
                    <p>Rejected Images</p>
                    <input type="checkbox" id="rejected_flag" name="rejected_flag" v-model="rejected_flag" :value="true">
                    <label for="rejected_flag"> Include Rejected</label>
                </td>
            </table>
            <input type="submit" value="Export">
        </form>
        <br>
        <div style="margin-left:30%;margin-right:30%;border-spacing:30px">
            <p>Selected Field Info:</p>
            <table style="width: 100%">
                <tr>
                    <td>Field Name</td>
                    <td>{{field_name}}</td>
                </tr>
                <tr>
                    <td>Field Org</td>
                    <td>{{field_json.organization_name}}</td>
                </tr>
                <tr>
                    <td>Planting Info</td>
                    <td>
                        <code style="text-align:left; display: inline-block;">
                            <pre>{{JSON.stringify(field_json.settings, null, 4)}}</pre>
                        </code>
                    </td>
                </tr>
                <tr>
                    <td>FieldAgent Analytic Link</td>
                    <td>
                        <a :href="field_json.activeadmin_link" target="_blank">{{ field_json.activeadmin_link }}</a>
                    </td>
                </tr>
                <tr>
                    <td><code>stand-qa-data</code> S3 Path</td>
                    <td>{{ field_json.s3_folder }}</td>
                </tr>
                
            </table>
            <br>
            <p>Full JSON:</p>
            <code style="text-align: left;">
                <pre>{{JSON.stringify(field_json, null, 4)}}</pre>
            </code>
        </div>
        <br>
    </div>
</template>
<style scoped>
input[type=checkbox] { 
    margin-right: 0.5em;
}
td {
    width: 12em
}
</style>
<script>
import { hit_terraform_api } from "@common/app/api_utils.js";
export default {
    data: function() {
        return {
            message: "",
            field_json: null,
            field_name: "",
            mf_selected: false,
            upload_flag: true,
            rejected_flag: true
        }
    },
    created() {
        this.field_json = this.$store.state.$field_json;
        this.field_name = this.$store.state.$field_name;
        console.log(this.field_name)
        console.log(this.mf_selected)
    },
    methods: {
        test() {
            console.log(this.mf_selected)
        },
        async runExport() {
            try {
                var export_type = document.querySelector('input[name="export_type"]:checked').id
                var units = document.querySelector('input[name="units"]:checked').id 
                // var rowfill = document.querySelector('input[name="rowfill"]:checked').checked

                let params = { 
                    "field_json": this.field_json,
                    "units": units,
                    "include_rejected": String(document.querySelector('input[name="rejected_flag"]').checked),
                    "upload_to_fa": String(document.querySelector('input[name="upload"]').checked),
                    "upload_as_released": String(document.querySelector('input[name="release"]').checked)
                }
                var data, endpoint_str;
                switch (export_type) { 
                    case "mf_exp":
                        endpoint_str = "ecs_fast_export";
                        params["rowfill_male"] = String(document.querySelector('input[name="rowfill_male"]').checked)
                        params["rowfill_female"] = String(document.querySelector('input[name="rowfill_female"]').checked)
                        break;
                    case "sv_exp":
                        endpoint_str = "ecs_sv_export";
                        params["rowfill"] = String(document.querySelector('input[name="rowfill"]').checked)
                        break;
                    default:
                        alert("Not implemented.")
                        return
                }
                console.log(endpoint_str)
                console.log(params)
                data = await hit_terraform_api(params, endpoint_str);
                alert(data.data);
            }
            catch (error) {
                alert("Failed to start export.");
                console.log(error)
            }
        }
    }
}
</script>
