//Handle caching stuff in field and image tags.

import { get_lat_lon_from_url } from "./annotation_loaders";
import { hit_terraform_api } from "./api_utils";
const { PromisePool } = require('@supercharge/promise-pool')

/**
 * Load an image lat/lon, or get the value and cache it if it's not present.
 * @param {*} field_json Field JSON
 * @param {*} image_name Image Name
 * @param {*} url URL to the image
 */
export async function cache_load_url_lat_lon(field_json, image_tags, image_name, url) {
    // TODO come up with better constants
    if(image_name in image_tags && "coords" in image_tags[image_name]) {
        return image_tags[image_name]["coords"];
    }
    const coords = await get_lat_lon_from_url(url);

    // TODO decide whether to async this puppy or nah 
    await hit_terraform_api(
        {
            "field_json": field_json,
            "image_name": image_name,
            "tag_name": "coords",
            "tag_dict": coords
        },
        "add_image_tag"
    )

    return coords; 
}

/**
 * Cache-load all lat/lon image tags
 * @param {*} field_json 
 * @param {*} images_tags 
 * @param {*} urls 
 */
export async function cache_load_all_url_lat_lons(field_json, images_tags, urls, images) {
    var iter_map = urls.map(function(e, i) {
        return [e, images[i]];
    });
    // eslint-disable-next-line no-unused-vars
    const { results, errors } = await PromisePool
    .withConcurrency(10)
    .for(iter_map)
    // eslint-disable-next-line no-unused-vars
    .process(async (iter_map_item, index, pool) => {
        var [url, image_name] = iter_map_item;
        return await cache_load_url_lat_lon(field_json, images_tags, image_name, url);
    })
    return results 
}
