<template>
    <div id="select-page-div" class="div-flex-col">
        <h1>Select Field</h1>
        <form v-on:submit.prevent="selectField" class="div-flex-row">
            <div class="div-flex-col">
                <div class="div-flex-row">
                    <label class="selector-label">Field Selector:</label>
                    <select v-model="selected_field_group" @change="on_field_group_change" name="field-group"
                        id="field-group-selector" required>
                        <option disabled value>--Select Field Group--</option>
                        <option v-for="group in ux_field_groups" :key="group">
                            {{ group }}
                        </option>
                    </select>
                </div>
                <label>Currently Selected: {{ field_name }}</label>
            </div>
            <div class="dropdown" v-if="selected_field_group">
                <label class="selector-label" for="selected-field-group-search">Select Field
                    JSON:</label>
                <input autocomplete="off" id="selected-field-group-search" type="text" @input="filter_dropdown" />
                <div id="field-filters" class="div-flex-row">
                    <button type="button" @click="change_base_filter('name')">
                        <img src="@common/assets/icons/filter.svg" />Name
                    </button>
                    <button type="button" @click="change_base_filter('date')">
                        <img src="@common/assets/icons/filter.svg" />Date
                    </button>
                </div>
                <div class="div-flex-col">
                    <div class="dropdown-link-holder">
                        <button v-for="field in filtered_fields" :key="field" @click="update_input(field.name)">
                            {{ field.name }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
        <br />
    </div>
</template>
<script>
import { hit_terraform_api } from '@common/app/api_utils';
import { ecs_create_rgb_thumbnails, ecs_create_bin_thumbnails, ecs_create_row_thumbnails } from "@common/app/api_endpoints";
export default {
    data: function () {
        const get_default_field_group = function () {
            const saved_field_group = localStorage.getItem("default_field_group")
            return (saved_field_group === null) ? "" : saved_field_group
        }

        return {
            field_name: "",
            field_names: [],
            field_groups: [],
            ux_field_groups: [], // Unique field groups
            image: "",
            selected_field: "",
            selected_field_group: get_default_field_group(),
            field_json: null,
            base_filter: "date",

            // Object with one key for each field group. 
            // {group1: [...], group2: [...]}
            // Each of those keys holds a list of objects
            // [{name: name, json: json}, {name: name, json: json} ... ]
            fields_by_group: {},
            update_counter: 0,
            filtered_fields: [],
        };
    },

    // Render the current field list
    mounted() {
        this.field_name = this.$store.state.$field_name;
        this.field_json = this.$store.state.$field_json;
        this.getFieldList();
    },

    // After component is mounted, but before the dom renders
    beforeUpdate() {
        this.filter_dropdown()
    },

    // Component methods
    methods: {
        /**
         * Get the field names currently stored on ddb and add them to the
         * page's field_names attribute.
         */
        async getFieldList() {
            let field_list = await hit_terraform_api(null, "get_field_list");
            console.log(field_list);
            this.field_names = field_list.data.field_names;
            this.field_jsons = field_list.data.field_jsons;
            this.field_groups = field_list.data.field_groups;

            // Get a list of all the unique field group names
            this.ux_field_groups = this.field_groups.filter((v, i, a) => a.indexOf(v) === i);

            // Group fields by field group
            for (var l = 0; l < this.ux_field_groups.length; l++) {
                var group_name = this.ux_field_groups[l];
                var fields = [];
                // var prefixes = [];
                for (var j = 0; j < this.field_names.length; j++) {
                    if (group_name === this.field_groups[j]) {
                        fields.push({
                            "name": this.field_names[j],
                            "json": this.field_jsons[j],
                        })
                    }
                }
                group_name = this.ux_field_groups[l];

                // Sort in alpha order by name
                fields.sort((a, b) => a.name.localeCompare(b.name));
                this.fields_by_group[group_name] = fields;
            }

        },

        /**
         * Set the currently selected field as the field to work
         */
        async selectField() {
            var fields = this.fields_by_group[this.selected_field_group];
            // Find json linked to the selected field
            var i = 0;
            while (fields[i]["name"] != this.selected_field) {
                i++;
            }
            var field_json = fields[i]["json"]; // Get the correct field_json
            var split = this.selected_field.split(" - - - "); // Split name into [org, field_name]
            var field_name = split[split.length - 1]; // Get last part of the split array, which recovers the field_name key
            function confirm_dialog(msg) {
                return new Promise(function (res, rej) {
                    let confirmed = window.confirm(msg);
                    return confirmed ? res(true) : rej(false);
                });
            }

            confirm_dialog(field_name).then(async () => {
                // Store selected field
                this.field_name = field_name;
                this.field_json = field_json;
                this.$store.commit("update$field_name", field_name);
                this.$store.commit("update$field_json", field_json);

                // Check for field tag binary set
                let params = {
                    field_json: this.field_json,
                }
                let data = await hit_terraform_api(params, "get_binary_field_tag")
                console.log(data);

                // Update store with active binary set
                this.$store.commit("update$binary_set", data.data);
                let bin_folder = "binaries/" + data.data;
                console.log(bin_folder);
                
                // TODO: Loading wheel or smth?
                // alert("Verifying thumbnail creation, please press ok and wait...");

                // Create thumbnails if they don't exist already
                // TODO: Prompt user to wait until tasks have started
                console.log(await ecs_create_rgb_thumbnails(this.field_json));
                console.log(await ecs_create_bin_thumbnails(this.field_json, bin_folder));
                console.log(await ecs_create_row_thumbnails(this.field_json));

                var redirect = {
                    name: "Select Field",
                    params: { field_name: field_name },
                }; // Reload page with new field_name param
                this.$router.push(redirect).catch(() => { });

                // alert("Thumbnails verified.");
            });
        },

        async getBinarySet() {
            // Add field_tag to ddb
            var params = {
                field_json: this.field_json,
            }

            console.log(await hit_terraform_api(params, "get_binary_field_tag"));
        },

        on_field_group_change: function () {
            this.save_default_field_group()
            this.filter_dropdown()
        },

        update_input: function (field_name) {
            this.selected_field = field_name
        },

        filter_dropdown: function () {
            // Grab the text from the clicked button and the div that holds the buttons
            let filter_input_element = document.getElementById("selected-field-group-search")
            let input_text = "";
            if (filter_input_element != null) {
                input_text = filter_input_element.value
            }
            this.filtered_fields = [];

            // Repopulate the button holder with all buttons who's name contains the input text
            for (let field_index in this.fields_by_group[this.selected_field_group]) {
                let field = this.fields_by_group[this.selected_field_group][field_index]

                // Break up the input text at a " " and check if any substring is in the field name
                let input_list = input_text.split(" ");

                if (this.check_list_for_substring(input_list, field.name)) {
                    this.filtered_fields.push(field);
                }
            }
            switch (this.base_filter) {
                case "date":
                    // eslint-disable-next-line no-case-declarations
                    let compare_fn = function (a, b) {
                        try {
                            return b.json.order_datetime.localeCompare(a.json.order_datetime);
                        }
                        catch (e) {
                            return 0;
                        }
                    }
                    this.filtered_fields.sort(compare_fn);
                    break;
                case "name":
                default:
                    this.filtered_fields.sort((a, b) => a.name.localeCompare(b.name));
                    break;
            }
            this.update_counter += 1;
        },

        change_base_filter: function (new_filter) {
            this.base_filter = new_filter;
            this.filter_dropdown();
            this.update_counter += 1;
        },

        check_list_for_substring: function (substring_list, string) {
            for (let string_index in substring_list) {
                if (string.toLowerCase().includes(substring_list[string_index].toLowerCase())) {
                    continue
                } else {
                    return false
                }
            }
            return true
        },

        save_default_field_group: function() {
            localStorage.setItem("default_field_group", this.selected_field_group)
        }
    },
};
</script>

<style scoped>
#select-page-div {
    text-align: left;
    margin-left: 10px;
}

#field-filters {
    margin-bottom: 10px;
}

#selected-field-group-search {
    width: 30em;
}

#selected-field-group-search:focus {
    outline: 3px solid #ddd;
}

#field-group-selector {
    height: fit-content;
}

.dropdown {
    position: relative;
    display: inline-block;
    padding: 0 1em;
}

.div-flex-col {
    display: flex;
    flex-direction: column;
}

.div-flex-row {
    display: flex;
    flex-direction: row;
}

.selector-label {
    padding-right: 0.5em;
}

.dropdown-link-holder {
    position: absolute;
    background-color: #f6f6f6;
    border: 1px solid black;
    min-width: 230px;
    border: 1px solid #ddd;
    z-index: 1;
    max-height: 24em;
    overflow: auto;
    width: 95%;
    box-shadow: 0 0 .1em black;
    /* visibility: hidden; */
}

.dropdown-link-holder button {
    background-color: white;
    color: black;
    padding: 0 1em;
    border: none;
    text-decoration: none;
    text-align: left;
    display: block;
    width: 100%;
}

.dropdown-link-holder button:hover {
    background-color: dodgerblue;
    color: white;
}
</style>