// Commonly used constants within the frontend application.

export const ULABEL_SUBTASK_IDS = { 
    PLANT_COUNT_QA: "plant_counting",
    ROW_QA: "row_classification",
    SUBIMAGE_BOUNDS: "subimage_bounds",
}

export const ULABEL_SUBTASK_DISPLAY_NAMES = {
    [ULABEL_SUBTASK_IDS.PLANT_COUNT_QA]: "Plant Counting",
    [ULABEL_SUBTASK_IDS.ROW_QA]: "Row Classification",
    [ULABEL_SUBTASK_IDS.SUBIMAGE_BOUNDS]: "Subimage Bounds",
}