<template>
    <div>
        <div
            id="container"
            style="
                width: 100%;
                height: calc(100vh - 66px);
                position: absolute;
                top: 66px;
                left: 0;
            "
        >
        </div>
    </div>
</template>

<script>
import { hit_terraform_api } from "@common/app/api_utils.js";
import { mark_is_modified_by, get_username, unlock_image } from '@common/app/qa_utils.js';

export default {
    created() {
        // Immediately reject and redirect to progress
        this.ddbRejectRow();
    },

    methods: {
        async ddbRejectRow() {

            // Send image to be rejected in field_json
            var params = {
                field_json: this.$store.state.$field_json,
                image_name: this.$store.state.$image_name,
                qa_type: this.$store.state.$gender_qa ? "gender_qa" : "row_qa",
            };

            let user = get_username(this);
            mark_is_modified_by(this.$store.state.$field_json, this.$store.state.$image_name, user);
            // Unlock
            await unlock_image(params.field_json, params.image_name, user);

            // Return to progress or start next image
            if (this.$store.state.$break) {
                // Await so it will show up on progress screen
                await hit_terraform_api(params, "reject_qa");
                this.$store.commit("update$break", false);
                this.$router.push({
                    name: "Progress",
                });
            } else {
                // Await so same job won't be selected again
                await hit_terraform_api(params, "reject_qa");
                if (this.$store.state.$gender_qa) {
                    this.$router.push({
                        name: "Gender QA",
                    });
                } else {
                    this.$router.push({
                        name: "Plant Or Row QA",
                    });
                }
                
            }
        },  
    }
};
</script>
