<template>
    <div>
        <h2>MFStand QA Home</h2>
        <a
            :href="'//sentera.atlassian.net/wiki/spaces/SPO/pages/3047489599/Stand+QA+tool+Keybinds'" 
            target="_blank"
        >
            Useful Keybinds
        </a>
    </div>
</template>
<script>
import UserInfoStore from '@/app/user-info-store';
export default {
    name: 'Home',
    data: function() {
        return{
            userInfo: UserInfoStore.state.cognitoInfo
        }
    },
}
</script>
<style>
</style>
