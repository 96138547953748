// Utility functions for common ulabel operations.

/**
 * Util to delete annotations within a recently drawn bbox
 * @param {*} ulabel ulabel object
 */
export function delete_annos_in_bbox(ulabel) {
    var subtask = "plant_counting";
    var action_stream =
        ulabel.subtasks[subtask]["actions"]["stream"];
    if(action_stream.length <= 0) {
        return;
    }
    var most_recent_action = action_stream[action_stream.length - 1];
    var annotation_type = most_recent_action["redo_payload"]["annotation_mode"];
    if (annotation_type != "bbox") {
        return;
    }
    
    if (most_recent_action["act_type"] == "begin_annotation") {
        var act_id =
            most_recent_action["redo_payload"]["unq_id"];
        var box_filter_anno =
            ulabel.subtasks[subtask]["annotations"]["access"][
                act_id
            ];
        let inside_box = (test_point, p1, p2) => {
            var min_x = Math.min(p1[0], p2[0]);
            var max_x = Math.max(p1[0], p2[0]);
            var min_y = Math.min(p1[1], p2[1]);
            var max_y = Math.max(p1[1], p2[1]);
            return (
                test_point[0] >= min_x &&
                test_point[0] <= max_x &&
                test_point[1] >= min_y &&
                test_point[1] <= max_y
            );
        };
        let point_inside_anno = (point_anno, box_anno) => {
            return inside_box(
                point_anno.spatial_payload[0],
                box_anno.spatial_payload[0],
                box_anno.spatial_payload[1]
            );
        };
        // For now, assume all drawn bbox are delete bbox
        if (box_filter_anno["spatial_type"] == "bbox") {
            // Overwrite all as male
            var current_annotations =
                ulabel.get_annotations(subtask);
            var new_annotations = [];
            var i = 0;
            for (i = 0; i < current_annotations.length; i++) {
                var current_anno = current_annotations[i];
                if (current_anno.id != act_id) {
                    if (
                        !point_inside_anno(
                            current_anno,
                            box_filter_anno
                        )
                    ) {
                        new_annotations.push(current_anno);
                    }
                }
            }
            ulabel.set_annotations(new_annotations, subtask);
        }
        ulabel.redraw_all_annotations();
    }
}

function point_inside_polygon(point, vs) {
    // ray-casting algorithm based on
    // https://wrfranklin.org/Research/Short_Notes/pnpoly.html
    
    var x = point[0], y = point[1];
    
    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        var xi = vs[i][0], yi = vs[i][1];
        var xj = vs[j][0], yj = vs[j][1];
        
        var intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    
    return inside;
}

export function delete_annos_in_polygon(ulabel) {
    var subtask = "plant_counting";
    var action_stream =
        ulabel.subtasks[subtask]["actions"]["stream"];
    if(action_stream.length <= 0) {
        return;
    }
    var action;
    for(var i = action_stream.length - 1; i >= 0; i--) {
        action = action_stream[i];
        if(action["act_type"] == "begin_annotation") {
            break;
        }
    }

    var annotation_type = action["redo_payload"]["annotation_mode"];
    // console.log(annotation_type)
    if (annotation_type == "polygon") {
        var annotation_id = action["redo_payload"]["unq_id"];
        var polygon_annotation = ulabel.subtasks[subtask]["annotations"]["access"][annotation_id];
        var current_annotations = ulabel.get_annotations(subtask);
        var new_annotations = [];
        for(var j = 0; j < current_annotations.length; j++) {
            var current_anno = current_annotations[j];
            if(current_anno.id != annotation_id) {
                if(!point_inside_polygon(
                    current_anno.spatial_payload[0],
                    polygon_annotation.spatial_payload
                )) {
                    new_annotations.push(current_anno);
                }
            }
        }
        ulabel.set_annotations(new_annotations, subtask);
        ulabel.redraw_all_annotations();
    }
    
    // console.log(most_recent_action);
    // console.log(action_stream);
}

/**
 * Util to get the most recent action 
 * in a specific ulabel subtask, or return null
 * if no actions have been performed.
 * 
 * @param {*} ulabel 
 * @param {string} subtask_name 
 * @returns most_recent_action
 */
export function get_most_recent_action(ulabel, subtask_name) {
    let action_stream = ulabel.subtasks[subtask_name]["actions"]["stream"];
    let annotation_ids = ulabel.subtasks[subtask_name]["annotations"]["ordering"];
    let most_recent_action = null;
    if (action_stream.length > 0 || annotation_ids.length > 0) {
        // No actions hack, TODO do this better
        if (action_stream.length == 0) {
            // actid goes to the most last object
            most_recent_action = {
                "act_type": "bypass",
                "redo_payload": {
                    "actid": annotation_ids[annotation_ids.length - 1]
                }
            };
        } else {
            most_recent_action = action_stream[action_stream.length - 1];
        }
    }
    return most_recent_action;
}

/**
 * Get the currently hovered annotation in
 * a ulabel subtask
 * 
 * @param {*} ulabel 
 * @param {String} subtask_name 
 * @returns [annotation, annid]
 */
export function get_hovered_annotation(ulabel, subtask_name) {
    // This is probably hacky but seems to work
    // I will note that this gets the most recently hovered anno,
    // even when you're hovering nothing
    let annid = null;
    let annotation = null;
    if (ulabel.subtasks[subtask_name]["state"]["edit_candidate"] != null) {
        annid = ulabel.subtasks[subtask_name]["state"]["edit_candidate"]["annid"];
        annotation = ulabel.subtasks[subtask_name]["annotations"]["access"][annid];
    }
    return [annotation, annid];
}

/**
 * Change the active class on a ulabel annotation
 * by cycling through the class list
 * 
 * @param {Object} annotation 
 * @returns {Object} annotation with the class changed
 */
function change_classification_payload(annotation) {
    let c_key = "classification_payloads";
    if (c_key in annotation) {
        let class_payload = annotation[c_key];
        let idx, conf;
        for (let [key, obj] of Object.entries(class_payload)) {
            idx = parseInt(key); // Key starts as a string of the array idx
            conf = obj["confidence"];
            if (conf == 1) { // Active class
                class_payload[idx]["confidence"] = 0; // Turn off
                if (idx < class_payload.length-1) { // Not at end of class list
                    class_payload[idx+1]["confidence"] = 1;
                } else { // At end of list, change first entry
                    class_payload[0]["confidence"] = 1;
                }
                break;
            }
        }
        annotation[c_key] = class_payload;
    }
    return annotation;
}

export function change_class(event, ulabel, subtask_name) {
    if (event.key == "g") {
        let [annotation, annid] = get_hovered_annotation(ulabel, subtask_name);
        if (annotation != null && annotation["spatial_type"] == "polyline") {
            change_classification_payload(annotation);
            ulabel.rebuild_containing_box(annid);
            ulabel.redraw_all_annotations();
        }
    }
}