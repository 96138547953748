<template>
    <div>
        <div id="navbar-break-div">
            <input type="button" id="navbar-break-button" value="Submit and Exit" v-on:click="take_a_break(true)"/>
            <input type="button" id="navbar-reject-break-button" value="Reject and Exit" v-on:click="take_a_break(false)"/>
        </div>
        <div
            id="container"
            style="
                width: 100%;
                height: calc(100vh - 74px);
                position: absolute;
                top: 74px;
                left: 0;
            "
        ></div>
    </div>
</template>
<script>
import $ from "jquery";
// import { ULabel } from "@common/app/ulabel_variants/ulabel.js";
import { ULabel as ulabel_both_filters } from "@common/app/ulabel_variants/ulabel-both-filters.js";
import { ULabel as ulabel_keypoint_filter_only } from "@common/app/ulabel_variants/ulabel-keypoint-filter-only.js";
import { extend_line, extend_all }  from "@common/app/ulabel_extend_line";
import { get_image_and_annotations, getMeta } from "@common/app/annotation_loaders.js";
import { delete_annos_in_bbox, change_class } from "@common/app/ulabel_utils.js";
import { hit_terraform_api } from "@common/app/api_utils.js";
import { lock_image, unlock_image, mark_is_modified_by, get_username } from "@common/app/qa_utils.js";
import { ecs_create_single_image_thumbnails, get_single_image_tags, get_binary_url, get_next_image_to_qa, get_all_field_tags } from "@common/app/api_endpoints.js";
import { get_subimage_qa_status } from "@common/app/ddb_utils.js";
import { ppa_qa_subtask, gendered_row_qa_subtask, subimage_bounds_subtask } from "@common/app/ulabel_subtasks.js";
import { ULABEL_SUBTASK_IDS } from "@common/app/constants.js";
import { make_all_subimage_bboxes } from "@common/app/ulabel_anno_utils.js";

export default {
    data: function () {
        return {
            field_name: "",
            image_name: "",
            ulabel: null,
            rgb_image_url: "",
            annotations: "",
            keypoint_annotations: "", 
            binary_image_url: "", 
            rgb_displayed: true,
            field_json: null,
            leave_qa_cycle: false,
            image_tags: "",
            gender_qa: false,
            grid: false, // Whether to redirect to grid
            n_h_subimages: 3,
            n_w_subimages: 3,
            image_height: 0,
            image_width: 0,
            ulabel_variant: null,
            opened_from_grid_as_new_tab: false
        };
    },
    created() {
        if (Object.prototype.hasOwnProperty.call(this.$route.params, "gender_qa")) {
            this.gender_qa = this.$route.params.gender_qa;
        }

        if (Object.prototype.hasOwnProperty.call(this.$route.query, "grid")) {
            if (this.$route.query.grid == "true") {
                this.opened_from_grid_as_new_tab = true;
            }
        }
        console.log(this.$route.params);
        console.log(this.$route.query);
        if (this.opened_from_grid_as_new_tab) { 
            // Copy relevant data from query to params
            this.$route.params.image_name = this.$route.query.image_name;
            this.$route.params.grid = this.$route.query.grid;
            this.$route.params.gender_qa = this.$route.query.gender_qa;
        }


        // Store values in case of reject
        this.$store.commit("update$image_name", this.image_name);
        this.$store.commit("update$gender_qa", this.gender_qa);

        window.addEventListener("keydown", (e) => {
            extend_line(e, this.ulabel, "row_classification");
            extend_all(e, this.ulabel, "row_classification");
            change_class(e, this.ulabel, "row_classification");
            if (e.key == "b") {
                if (this.rgb_displayed) {
                    this.rgb_displayed = false;
                    this.ulabel.swap_frame_image(this.binary_image_url);
                    console.log("Displaying binary.")
                }    
                else {
                    this.rgb_displayed = true; 
                    this.ulabel.swap_frame_image(this.rgb_image_url);
                    console.log("Displaying RGB.")
                }
            }
            if (e.key == "r") {
                this.ulabel.show_initial_crop();
            }
            if (e.key == "Enter") {
                document.getElementById("submit").click();
            }
        })
    },
    async mounted() {
        this.field_json = this.$store.state.$field_json;
        this.field_name = this.$store.state.$field_name;
        this.user = get_username(this);
        console.log("Username on mount: " + this.user);
        let qa_key = "row_qa"
        if (this.gender_qa) {
            qa_key = "gender_qa"
        }
        
        if (Object.prototype.hasOwnProperty.call(this.$route.params, "image_name")) { 
            this.image_name = this.$route.params.image_name;
            this.subimage_idx = this.$route.params.subimage_idx;
            if (Object.prototype.hasOwnProperty.call(this.$route.params, "n_h_subimages")){
                this.n_h_subimages = this.$route.params.n_h_subimages;
                this.n_w_subimages = this.$route.params.n_w_subimages;
                this.n_subimages = this.n_h_subimages * this.n_w_subimages;
            }
            else {
                this.n_h_subimages = 3;
                this.n_w_subimages = 3;
                this.n_subimages = 9;

            }
        }
        else {  // If no image_name is in the route params, we get the next available
            let next_image = await get_next_image_to_qa(
                this.field_json,
                "",
                qa_key,
                this.user
            )
            
            console.log(next_image)
            if (next_image == "Nothing to QA.")
            {
                location.href = "/progress"
            }
            this.image_name = next_image.image_name;
            this.subimage_idx = next_image.subimage_idx;
            this.n_h_subimages = 3;
            this.n_w_subimages = 3;
            this.n_subimages = 9;
        }

        // Store values in case of reject
        this.$store.commit("update$image_name", this.image_name);
        this.$store.commit("update$gender_qa", this.gender_qa);

        let lock_acquired = await lock_image(this.field_json, this.image_name, this.user);
        if (!lock_acquired) {
            alert("Image is locked by another user. Please try again later.");
            location.href = "/row_qa";
        }

        this.image_tags = await get_single_image_tags(this.field_json, this.image_name);
        this.field_tags = await get_all_field_tags(this.field_json);
        try {
            this.buffer_pct = parseInt(this.field_tags["image_buffer"]["value"]);
        }
        catch (e) {
            console.log("Error getting buffer_pct:", e);
        }
        console.log("Buffer pct:", this.buffer_pct)

        let row_distance_filter_active = true;
        if ('row_distance_filter_active' in this.field_tags) {
            row_distance_filter_active = this.field_tags['row_distance_filter_active']['value'];
        }

        // Attempt to conditionally use different ULabel exports
        if (!row_distance_filter_active) {
            this.ulabel_variant = ulabel_keypoint_filter_only;
            console.log("Using keypoint filter only.")
        }
        else { 
            this.ulabel_variant = ulabel_both_filters;
            console.log("Using both filters.")
        }
        
        // Get ppa status for thresholding determination
        let data = await get_subimage_qa_status(
            this.field_name, this.field_json, this.image_name
        );
        this.subimage_qa_status = data["subimages"];
        this.image_qa_status = data["ppa_qa"];
        this.load_and_run_all();
        this.build_break_button();
    },
    methods: {
        build_break_button() {
            // Attach the buttons to the navbar
            var navbar = document.getElementById("navbar");
            navbar.appendChild(document.getElementById("navbar-break-div"));
        },
        take_a_break(submit) {
            if (submit) {
                this.leave_qa_cycle = true;
                document.getElementById("submit").click();
            } else {
                // Normal reject workflow, but will return to progress after
                this.$store.commit("update$break", true);
                location.href = "/rejectRow";
            }
        },
        async load_and_run_all() {
            await this.get_image_and_row_annotations();
            this.start_ULabel();
        },
        async get_image_and_row_annotations() {
            var params = {
                field_name: this.field_name,
                field_json: this.field_json,
                image_name: this.image_name,
                annotation_dir: "row_annotations"
            }
            let [rgb_image_url, annotations] = await get_image_and_annotations(params);
            this.rgb_image_url = rgb_image_url;
            this.annotations = annotations;

            // Get image height and width
            let img = await getMeta(this.rgb_image_url);
            this.image_height = img.height;
            this.image_width = img.width;
            
            // Handle incorrectly serialized annotations.
            var spatial_payload;
            for(var i = 0; i < this.annotations.length; i++) {
                spatial_payload = this.annotations[i].spatial_payload;
                for(var sp_idx = 0; sp_idx < spatial_payload.length; sp_idx++) {
                    if('spatial_payload' in spatial_payload[sp_idx]) {
                        // TODO not be bad
                        this.annotations[i].spatial_payload[sp_idx] = spatial_payload[sp_idx].spatial_payload[0];
                    }
                }
            }
            
            // If we didn't load keypoint_annotations from redirect, use these
            if (this.keypoint_annotations == "") {
                params.annotation_dir = "annotations";
                [rgb_image_url, annotations] = await get_image_and_annotations(params);
                this.keypoint_annotations = annotations;
            }

            this.binary_image_url = await get_binary_url(this.field_json, this.image_name, this.$store.state.$binary_set);
        },
        async on_submit(annotations) {
            console.log(annotations);
            var post_annotations_params = {
                annotations_json: {
                    "row_annotations": annotations["annotations"]["row_classification"]
                },
                image_name: this.image_name,
                field_name: this.field_name,
                field_json: this.field_json, 
                reject: false,
                qa_type: this.gender_qa ? "gender_qa" : "row_qa"
            }
            hit_terraform_api(post_annotations_params, "save_row_qa");

            post_annotations_params = {
                annotations_json: {
                    annotations: {
                        main: [annotations["annotations"]["plant_counting"]],
                    },
                },
                image_name: this.image_name,
                field_json: this.field_json, 
                subimage_idx: -1
            };
            await hit_terraform_api(post_annotations_params, "save_ppa_qa");
            
            // Add user to is_modified_by list
            let user = get_username(this);
            mark_is_modified_by(this.field_json, this.image_name, user);

            // Set keypoint and row dist slider vals
            let slider_val;
            try {
                slider_val = $("input#filter-low-confidence")[0].value;
            }
            catch (e) { 
                // TODO not be bad 
                slider_val = $("input#keypoint-slider")[0].value;
            }

            let keypoint_slider_params = {
                field_json: this.field_json,
                image_name: this.image_name,
                tag_name: "keypoint_slider_val",
                tag_dict: {"value": slider_val}
            };
            hit_terraform_api(keypoint_slider_params, "add_image_tag");

            try {
                let row_dist_slider_val = $("input#FilterPointDistanceFromRow-slider")[0].value;
                let row_slider_params = {
                    field_json: this.field_json,
                    image_name: this.image_name,
                    tag_name: "row_dist_slider_val",
                    tag_dict: {"value": row_dist_slider_val}
                };
                hit_terraform_api(row_slider_params, "add_image_tag");
            }
            catch (e) { 
                console.log("Error getting row_dist_slider_val:", e);
            }

            // Update thumbnails
            console.log(await ecs_create_single_image_thumbnails(this.field_json, this.image_name))

            // Prevent leave page warning
            this.ulabel.set_saved(true)

            // If everything occurred as expected, unlock 
            await unlock_image(this.field_json, this.image_name, user);

            if (this.opened_from_grid_as_new_tab) {
                // Close new tab 
                window.close();
            } else if (this.leave_qa_cycle && !this.grid) {
                // Go back to progress
                location.href = "/progress";
            } else if (this.grid) {
                // Go back to grid
                location.href = "/grid";
            } else if (this.gender_qa) {
                // Continue doing gender qa
                location.href = "/gender_qa";
            } else {
                // Start next qa job
                location.href = "/row_qa";
            }
        },
        start_ULabel() {
            let annos = this.annotations;
            console.log(annos);
            let subimage_bbox_annos = make_all_subimage_bboxes(
                this.n_w_subimages,
                this.n_h_subimages,
                this.image_width,
                this.image_height,
                this.subimage_qa_status,
                this.buffer_pct,
                0.995, // resize_factor
            )
            let subtasks = {
                [ULABEL_SUBTASK_IDS.ROW_QA]: gendered_row_qa_subtask(annos),
                [ULABEL_SUBTASK_IDS.PLANT_COUNT_QA]: ppa_qa_subtask(this.keypoint_annotations),
                [ULABEL_SUBTASK_IDS.SUBIMAGE_BOUNDS]: subimage_bounds_subtask(subimage_bbox_annos, {inactive_opacity: 0.5}),
            };

            var email = get_username(this);
            if (email === undefined) {
                email = "Unauthenticated MFStand User"
            }
            console.log("Username on ULabel start: " + email);

            let keypoint_confidence_slider_val = 30; // Default to 30
            let row_dist_slider_val = 50; // Default to 50

            if ("keypoint_slider_val" in this.image_tags) {
                keypoint_confidence_slider_val = parseInt(this.image_tags["keypoint_slider_val"]["value"]);
                console.log("Keypoint slider value found in image tags: " + keypoint_confidence_slider_val);
            } else if ("keypoint_confidence_slider_default_value" in this.field_tags) {
                keypoint_confidence_slider_val = parseInt(this.field_tags["keypoint_confidence_slider_default_value"]["value"]);
                console.log("Keypoint slider default value found in field tags: " + keypoint_confidence_slider_val);
            } else {
                console.log("No keypoint slider value saved, leaving as default: " + keypoint_confidence_slider_val);
            }

            // This is invariant to whether or not QA has been performed
            if ("row_dist_slider_val" in this.image_tags) {
                row_dist_slider_val = parseInt(this.image_tags["row_dist_slider_val"]["value"]);
                console.log("Row dist slider value found in image tags: " + row_dist_slider_val);
            } else if ("row_distance_slider_default_value" in this.field_tags) {
                row_dist_slider_val = parseInt(this.field_tags["row_distance_slider_default_value"]["value"]);
                console.log("Row dist slider default value found in field tags: " + row_dist_slider_val);
            } else {
                console.log("No row dist slider value saved, leaving as default: " + row_dist_slider_val);
            }
            
            // Config object
            let config_data = {
                // TODO deprecate one of these
                "filter_low_confidence_default_value": keypoint_confidence_slider_val / 100,
                "keypoint_slider_default_value": keypoint_confidence_slider_val / 100,
                "filter_row_distance_default_value": row_dist_slider_val,
                "create_bbox_on_initial_crop": "" // Disable keybind
            };

            // Initial ULabel configuration
            let ulabel = new this.ulabel_variant(
                "container",        // container_id
                this.rgb_image_url, // image_data
                email,              // username
                this.on_submit,     // on_submit
                subtasks,           // subtasks
                null,               // task_meta
                null,               // annotation_meta
                1,                  // px_per_px
                null,               // initial_crop
                2,                  // Initial Line Size
                null,               // instructions_url
                config_data,        // config data
            );
            this.ulabel = ulabel;

            let ff_fn = this.force_filter;

            // Wait for ULabel instance to finish initialization
            ulabel.init(function () {
                Object.defineProperties(
                    ulabel.finish_annotation,
                    {
                        name:
                        {
                            value: "finish_annotation",
                            writable: true,
                        }
                    }
                );

                // Delete within bbox hack
                ulabel.on(ulabel.finish_annotation, () => {
                    delete_annos_in_bbox(ulabel);
                });

                // Force filter hack
                ff_fn();
            });

            // Hack to disallow submit before dirtying the page
            // document.getElementById("submit").removeAttribute("href");
        },
        // TODO not have this be duplicate with PlantCountQA.vue
        force_filter() {
            console.log("Forcibly clicking filter buttons...");
            // We have to forcibly click the subtask first
            let plant_counting_button = $("#tb-st-switch--plant_counting")[0];
            console.log(plant_counting_button)
            plant_counting_button.click();
            let inc_button = $(".button.inc.keypoint-slider-increment.keypoint-slider-button")[0];
            let dec_button = $(".button.dec.keypoint-slider-increment.keypoint-slider-button")[0];
            console.log(inc_button);
            console.log(dec_button);
            inc_button.click();
            dec_button.click();
            // Switch back to Row QA
            let row_qa_button = $("#tb-st-switch--row_classification")[0];
            console.log(row_qa_button)
            row_qa_button.click();
        },
    },
};
</script>

<style>

</style>