<template>
    <div>
        <div
            id="container"
            style="
                width: 100%;
                height: calc(100vh - 66px);
                position: absolute;
                top: 66px;
                left: 0;
            "
        >
        </div>
    </div>
<!-- <div>
    <input type="button" value="Reject" v-on:click="ddbRejectSubimage()">
</div> -->
</template>

<script>
import { hit_terraform_api } from "@common/app/api_utils.js";
import { mark_is_modified_by, get_username } from '@common/app/qa_utils.js';

export default {
    created() {
        // Immediately reject and redirect to PPA
        this.ddbRejectSubimage();
    },

    methods: {
        async ddbRejectSubimage() {
            // Take subimage_idx passed from PPA QA
            // and mark it as rejected in ddb

            // Send subimage to be rejected in field_json
            var params = {
                field_json: this.$store.state.$field_json,
                image_name: this.$store.state.$image_name,
                qa_type: "keypoint_qa",
                subimage_idx: this.$store.state.$subimage_idx,
            };

            hit_terraform_api(params, "reject_qa");
            mark_is_modified_by(this.$store.state.$field_json, this.$store.state.$image_name, get_username(this));
            
            if (this.$store.state.$subimage_idx + 1 == this.$store.state.$n_h_subimages * this.$store.state.$n_w_subimages) {
                // Image is done, go to next qa job
                this.$router.push({
                    name: "Plant Or Row QA",
                });
            } else {
                // Increment subimage, return to PPA QA
                this.$router.push({
                    name: "PPA QA",
                    params: {
                        field_name: this.$store.state.$field_name,
                        image_name: this.$store.state.$image_name,
                        subimage_idx: this.$store.state.$subimage_idx + 1,
                        n_h_subimages: this.$store.state.$n_h_subimages,
                        n_w_subimages: this.$store.state.$n_w_subimages,
                    }
                });
            }
        }
    }
};
</script>
