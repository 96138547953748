// Loaders for annotations
import EXIF from 'exif-js';
import { get_image_and_annotations_api, get_progress_json } from "./api_endpoints.js"
const { PromisePool } = require('@supercharge/promise-pool')


export function getMeta(url) {
    return new Promise((res, rej) => {
        let img = new Image();
        img.onload = () => res(img);
        img.onerror = () => rej();
        img.src = url;
    });
}

/**
 * Get EXIF data from a file url
 * @param {*} url URL to get EXIF data from
 * @returns EXIF data
 */
export async function get_exif_from_url(url) {
    let array_buf = await fetch(url).then(r => r.arrayBuffer());
    return await EXIF.readFromBinaryFile(array_buf);
}

/**
 * Get latitude an longitude from EXIF data for a file url
 * @param {*} url URL to get lat/lon from 
 * @returns Lat/lon 
 */
export async function get_lat_lon_from_url(url) {
    let exif = await get_exif_from_url(url);
    var lat_sign = 1;
    if(exif.GPSLatitudeRef == "S") {
        lat_sign = -1;
    }
    var lon_sign = 1;
    if(exif.GPSLongitudeRef == "W") {
        lon_sign = -1;
    }
    var lat = exif.GPSLatitude;
    var lon = exif.GPSLongitude;
    return {
        lat: lat_sign * (lat[0] + lat[1] / 60 + lat[2] / 3600),
        lon: lon_sign * (lon[0] + lon[1] / 60 + lon[2] / 3600) 
    }
}

/**
 * Get all lat/lons from a list of file urls
 * @param {*} urls URLS to get lat/lons from
 * @returns list of lat/lons
 */
export async function get_all_lat_lons(urls) {
    // eslint-disable-next-line no-unused-vars
    const { results, errors } = await PromisePool
    .withConcurrency(20)
    .for(urls)
    // eslint-disable-next-line no-unused-vars
    .process(async (url, index, pool) => {
        return await get_lat_lon_from_url(url);
    });
    return results;
}


export async function get_image_and_annotations(params) {
    let anno_format_variant_fns = [
        (annos) => {return annos["annotations"]["main"][0]},
        (annos) => {return annos["annotations"]},
        (annos) => {return annos["row_annotations"]},
    ]

    let [rgb_image_url, annotation_json] = await get_image_and_annotations_api(params.field_json, params.annotation_dir, params.image_name);
    console.log(annotation_json);
    let annotations;
    for (let i = 0; i < anno_format_variant_fns.length; i++) {
        try {
            annotations = anno_format_variant_fns[i](annotation_json);
            
            // js doesn't error when accessing a non-existent key, it just returns undefined
            if (annotations == undefined) {
                continue;
            }
            break;
        }
        catch (e) {
            // js WILL error when performing operations on undefined
            continue;
        }
    }
    if (!annotations) {
        throw new Error("Could not parse annotations");
    }
    return [
        rgb_image_url,
        annotations,
    ]
}

export async function get_progress_data(field_name, field_json) {
    let re = / /gi;
    field_name = field_name.replace(re, "_");
    let params = {
        field_json: field_json
    };

    let progress_json = await get_progress_json(params)
    var image_data = progress_json["files"];
    var image_data_arr = [];
    for (var key in image_data) {
        image_data_arr.push([key, image_data[key]]);
    }
    image_data_arr.sort((a, b) => {
        return a[0].localeCompare(b[0]);
    });
    return image_data_arr;
}