// Shared functions for components that navigate btwn views (ie Progress screen)

/**
 * Launch a PPA QA for a given image/subimage combo
 * @param {*} page "this" of the active component 
 * @param {*} image_name image name
 * @param {*} subimage_idx subimage idx
 */
export function launch_ppa_qa(page, image_name, subimage_idx) {
    if (image_is_locked(page, image_name)) {
        alert("Image locked by " + page.image_data[image_name].locked_by + ", navigation canceled.");
    } else {
        page.$router.push({
            name: "PPA QA",
            params: {
                field_name: page.field_name,
                image_name: image_name,
                subimage_idx: subimage_idx,
                n_h_subimages: page.progress_json["files"][image_name][
                    "n_h_subimages"
                ],
                n_w_subimages: page.progress_json["files"][image_name][
                    "n_w_subimages"
                ],
            },
        });
    }
}

/**
 * Launch a Row QA for a given image
 * @param {*} page "this" of the active component 
 * @param {*} image_name image name
 * @param {boolean} grid whether to nav back to grid screen
 * @param {boolean} gender_qa whether to start gender qa
 * @param {boolean} new_tab whether to launch in new tab
 */
export function launch_row_qa(page, image_name, grid=false, gender_qa=false, new_tab=false) {
    if (image_is_locked(page, image_name)) {
        alert("Image locked by " + page.image_data[image_name].locked_by + ", navigation canceled.");
    } else if (new_tab) {
        let route_params = {
            field_name: page.field_name,
            image_name: image_name,
            grid: grid,
            gender_qa: gender_qa,
        }
        const route_data = page.$router.resolve(
            {
                name: "Row QA",
                query: route_params,
                params: route_params,
            }
        )
        console.log(window)
        console.log(page)
        console.log(route_data)
        window.open(
            route_data.href,
            '_blank',


        )
        window.open(route_data.href, '_blank');
    } else {
        page.$router.push({
            name: "Row QA",
            params: {
                field_name: page.field_name,
                image_name: image_name,
                grid: grid,
                gender_qa: gender_qa,
            },
        });
    }
    
}

/**
 * Check if an image is currently locked by someone other than the user
 * @param {*} page "this" of the active component
 * @param {*} image_name image name
 */
function image_is_locked(page, image_name) {
    let user = page.$parent.user.attributes.email;
    return page.image_data[image_name].IS_LOCKED && page.image_data[image_name].locked_by != user;
}