<template>
    <div>
        <div id="navbar-back-next">
            <input type="button" id="navbar-back-button" value="Back" v-on:click="go_without_saving(-1)" />
            <input type="button" id="navbar-next-button" value="Next" v-on:click="go_without_saving(1)" />&nbsp;
            <input type="button" id="navbar-break-button" value="Submit and Exit" v-on:click="take_a_break(true)" />
            <input type="button" id="navbar-submit-all-button" value="Submit All Subimages" v-on:click="submit_all()" />
        </div>
        <div id="container" style="
                        width: 100%;
                        height: calc(100vh - 66px);
                        position: absolute;
                        top: 66px;
                        left: 0;
                    "></div>
    </div>
</template>

<script>
import $ from "jquery";
// import { ULabel } from "@common/app/ulabel_variants/ulabel-both-filters.js";
import { ULabel as ulabel_both_filters } from "@common/app/ulabel_variants/ulabel-both-filters.js";
import { ULabel as ulabel_keypoint_filter_only } from "@common/app/ulabel_variants/ulabel-keypoint-filter-only.js";
import { extend_line, extend_all } from "@common/app/ulabel_extend_line";
import { get_subimage_qa_status } from "@common/app/ddb_utils.js";
import { get_image_and_annotations, getMeta } from "@common/app/annotation_loaders.js";
import { get_confidence_stats, ecs_create_single_image_thumbnails, get_single_image_tags, get_binary_url, get_all_field_tags } from "@common/app/api_endpoints.js";
import { hit_terraform_api } from '@common/app/api_utils.js';
import { delete_annos_in_bbox, delete_annos_in_polygon, change_class } from "@common/app/ulabel_utils.js";
import { lock_image, unlock_image, mark_is_modified_by, get_username } from "@common/app/qa_utils.js";
import { get_next_image_to_qa } from "@common/app/api_endpoints.js";
import { ppa_qa_subtask, gendered_row_qa_subtask, subimage_bounds_subtask } from "@common/app/ulabel_subtasks.js";
import { ULABEL_SUBTASK_IDS } from "@common/app/constants.js";
import { get_subimage_crop_from_idx, get_ulabel_initial_crop_from_bbox, make_ulabel_bbox } from "@common/app/ulabel_anno_utils.js";

export default {
    data() {
        return {
            message: "",
            field_name: "",
            field_json: null,
            image_name: "",
            subimage_idx: -2,
            ulabel: null,
            rgb_image_url: "",
            binary_image_url: "",
            rgb_displayed: true,
            qa_status: [], // list of all subimage qa statuses
            subimage_qa_status: "",
            image_qa_status: "",
            image_tags: "",
            stats: {},
            user: "",
            row_annotations: [],
            n_subimages: 9,
            leave_qa_cycle: false,
            submit_all_subimages: false,
            field_tags: {},
            buffer_pct: 0,
            confidence_threshold: null,
            ulabel_variant: null,
        };
    },
    created() {
        window.addEventListener("keydown", (e) => {
            extend_line(e, this.ulabel, "row_classification"); // e
            extend_all(e, this.ulabel, "row_classification");  // shift+e
            change_class(e, this.ulabel, "row_classification"); // g
            if (e.key == "r") {
                this.ulabel.show_initial_crop();
            }
            if (e.key == "b") {
                if (this.rgb_displayed) {
                    console.log("Swap to binary");
                    this.rgb_displayed = false;
                    this.ulabel.swap_frame_image(this.binary_image_url);
                }
                else {
                    console.log("Swap to RGB");
                    this.rgb_displayed = true;
                    this.ulabel.swap_frame_image(this.rgb_image_url);
                }
            }
            if (e.key == "Enter") {
                document.getElementById("submit").click();
            }
        });
    },
    async mounted() {
        this.field_json = this.$store.state.$field_json;
        this.field_name = this.$store.state.$field_name;
        this.user = get_username(this);
        console.log("Username on mount: " + this.user);

        if (Object.prototype.hasOwnProperty.call(this.$route.params, "image_name")) {
            this.image_name = this.$route.params.image_name;
            this.subimage_idx = this.$route.params.subimage_idx;
            this.n_h_subimages = this.$route.params.n_h_subimages;
            this.n_w_subimages = this.$route.params.n_w_subimages;
            this.n_subimages = this.n_h_subimages * this.n_w_subimages;
        }
        else {  // If no image_name is in the route params, we get the next available
            let next_image = await get_next_image_to_qa(
                this.field_json,
                "",
                "keypoint_qa",
                this.user
            )
            console.log(next_image)
            // TODO better constants
            if (next_image == "Nothing to QA.") {
                console.log("Nothing to QA, returning to Progress screen.")
                location.href = "/progress"
            }
            this.image_name = next_image.image_name;
            this.subimage_idx = next_image.subimage_idx;
            this.n_h_subimages = 3;
            this.n_w_subimages = 3;
            this.n_subimages = 9;
        }


        this.image_height = 0;
        this.image_width = 0;
        this.update_store();

        this.build_back_and_next_buttons();

        // Get image tags
        this.image_tags = await get_single_image_tags(this.field_json, this.image_name);
        this.field_tags = await get_all_field_tags(this.field_json);

        // Check row distance filter
        let row_distance_filter_active = true;
        if ('row_distance_filter_active' in this.field_tags) {
            row_distance_filter_active = this.field_tags['row_distance_filter_active']['value'];
        }
        if (row_distance_filter_active) {
            this.ulabel_variant = ulabel_both_filters;
            console.log("Row distance filter active.")
        } 
        else {
            this.ulabel_variant = ulabel_keypoint_filter_only;
            console.log("Row distance filter disabled.")
        }

        // Check qa_status for current subimage
        await this.load_and_run_all();
    },
    methods: {
        display_qa_status() {
            this.subimage_qa_status = this.qa_status[this.subimage_idx]["keypoint_qa"];
            document.getElementById("qa_status").innerHTML = "Plant QA Status: " + this.subimage_qa_status;
        },
        build_back_and_next_buttons() {
            // Attach the buttons to the navbar
            var navbar = document.getElementById("navbar");
            navbar.appendChild(document.getElementById("navbar-back-next"));
        },
        take_a_break(submit) {
            if (submit) {
                this.leave_qa_cycle = true;
                document.getElementById("submit").click();
            } else {
                // Normal reject workflow, but will return to progress after
                this.$store.commit("update$break", true);
                location.href = "/rejectPPA";
            }
        },
        submit_all() {
            this.submit_all_subimages = true;
            document.getElementById("submit").click();
        },
        go_without_saving(idx_change) {
            // Navigate to previous image
            var new_subimage_idx = this.subimage_idx + idx_change;

            // Prevent out of bounds navigation
            if (new_subimage_idx < 0 || new_subimage_idx >= this.n_subimages) {
                console.log("Prevent out of bounds navigation");
            } else {
                // TODO find new way to prevent nav when page is dirty
                this.subimage_idx = new_subimage_idx;
                this.update_store();
                this.reload_ulabel();
            }

        },
        async populate_confidence_stats() {
            try {
                let stats = await get_confidence_stats(this.field_json);
                console.log("Stats:", stats);
                let statsDiv = document.getElementById("confidence-stats");
                statsDiv.innerHTML = "Mean: " + stats.mean + " Median: " + stats.median + " Max: " + stats.max + " Min: " + stats.min;
            }
            catch (e) {
                console.log("Error getting confidence stats:", e);
            }
        },
        update_store() {
            // Store values in case of reject
            this.$store.commit("update$field_json", this.field_json);
            this.$store.commit("update$image_name", this.image_name);
            this.$store.commit("update$subimage_idx", this.subimage_idx);
            this.$store.commit("update$n_h_subimages", this.n_h_subimages);
            this.$store.commit("update$n_w_subimages", this.n_w_subimages);
            this.$store.commit("update$user", this.user);
        },
        async load_and_run_all() {
            console.log(this.field_json)
            console.log(this.image_name)
            console.log(this.user)
            if (this.user == "MFStand QA User") {
                this.user = get_username(this);
            }
            // Kick off all the async functions
            let lock_acq_prom = lock_image(this.field_json, this.image_name, this.user);

            let field_tags_promise = get_all_field_tags(this.field_json);
            let subimage_qa_status_promise = get_subimage_qa_status(
                this.field_name, this.field_json, this.image_name
            );
            let image_promise = this.get_image();


            // Wait for lock to be acquired, exit if not 
            let lock_acquired = await lock_acq_prom;
            if (!lock_acquired) {
                alert("Image is locked by another user. Please try again later.");
                location.href = "/plant_count_qa";
            }

            // Await other data
            let data = await subimage_qa_status_promise;
            this.qa_status = data["subimages"];
            this.image_qa_status = data["ppa_qa"];
            this.display_qa_status();

            await image_promise
            this.populate_confidence_stats();

            this.field_tags = await field_tags_promise;
            try {
                this.buffer_pct = parseInt(this.field_tags["image_buffer"]["value"]);
            }
            catch (e) {
                console.log("Error getting buffer_pct:", e);
            }
            console.log("Buffer pct:", this.buffer_pct)

            this.startULabel();
        },
        async reload_ulabel() {
            let annos = this.preproc_annotations(this.annotations);

            // Update subimage qa status
            this.display_qa_status();

            // Hack to update navbar div 
            document.getElementById("navbar-p").innerHTML = "Subimage " + (this.subimage_idx + 1) + " of " + this.n_subimages + ", " + this.image_name + " &nbsp";


            let subimage_crop = get_subimage_crop_from_idx(
                this.n_w_subimages,
                this.n_h_subimages,
                this.image_width,
                this.image_height,
                this.subimage_idx,
                this.buffer_pct
            )
            let ulabel_initial_crop = get_ulabel_initial_crop_from_bbox(...subimage_crop);
            let subimage_anno = make_ulabel_bbox(...subimage_crop)

            this.ulabel.swap_frame_image(this.rgb_image_url);

            this.ulabel.set_annotations(annos, "plant_counting");
            this.ulabel.set_annotations([subimage_anno], "subimage_bounds");
            this.ulabel["config"]["initial_crop"] = ulabel_initial_crop;
            this.ulabel.show_initial_crop();
        },

        read_size_cookie() {
            let subtask_name = "plant_counting"
            let cookie_name = subtask_name + "_size=";
            let cookie_array = document.cookie.split(";");
            for (let i = 0; i < cookie_array.length; i++) {
                let current_cookie = cookie_array[i];
                //while there's whitespace at the front of the cookie, loop through and remove it
                while (current_cookie.charAt(0) == " ") {
                    current_cookie = current_cookie.substring(1);
                }
                if (current_cookie.indexOf(cookie_name) == 0) {
                    return parseFloat(current_cookie.substring(cookie_name.length, current_cookie.length))
                }
            }
            return null
        },

        async get_image() {
            // Get keypoints
            let params = {
                field_json: this.field_json,
                image_name: this.image_name,
                annotation_dir: "annotations"
            };
            let [rgb_image_url, annotations] = await get_image_and_annotations(params);
            this.rgb_image_url = rgb_image_url;
            this.annotations = annotations;

            // Get rows
            params.annotation_dir = "row_annotations";
            [rgb_image_url, annotations] = await get_image_and_annotations(params);
            this.row_annotations = annotations;

            // Get image height and width
            let img = await getMeta(this.rgb_image_url);
            this.image_height = img.height;
            this.image_width = img.width;

            var ext = this.image_name.split(".").slice(-1)[0]
            params = {
                field_json: this.field_json,
                image_name: this.image_name.replace(ext, "png"),
                binary_set: this.$store.state.$binary_set
            }

            this.binary_image_url = await get_binary_url(this.field_json, this.image_name, this.$store.state.$binary_set);
        },
        async on_submit(annotations) {
            // Get keypoint slider value
            // TODO have ULabel store this as a state which is easier to access 
            
            let slider_val;
            try {
                slider_val = $("input#filter-low-confidence")[0].value;
            }
            catch (e) { 
                // TODO not be bad 
                slider_val = $("input#keypoint-slider")[0].value;
            }

            // Set keypoint and row dist slider vals
            let keypoint_slider_params = {
                field_json: this.field_json,
                image_name: this.image_name,
                tag_name: "keypoint_slider_val",
                tag_dict: {"value": slider_val}
            };
            hit_terraform_api(keypoint_slider_params, "add_image_tag");

            try {
                let row_dist_slider_val = $("input#FilterPointDistanceFromRow-slider")[0].value;
                let row_slider_params = {
                    field_json: this.field_json,
                    image_name: this.image_name,
                    tag_name: "row_dist_slider_val",
                    tag_dict: {"value": row_dist_slider_val}
                };
                hit_terraform_api(row_slider_params, "add_image_tag");
            }
            catch (e) { 
                console.log("Error getting row_dist_slider_val:", e);
            }

            console.log(annotations);
            annotations = this.preproc_annotations(annotations);
            // Semantics to keep formatting consistent.
            var keypoint_post_annotations_params = {
                annotations_json: {
                    annotations: {
                        main: [annotations["annotations"]["plant_counting"]],
                    },
                },
                image_name: this.image_name,
                field_name: this.field_name,
                field_json: this.field_json,
                subimage_idx: this.subimage_idx,
            };

            let row_annotations_params = {
                annotations_json: {
                    "row_annotations": annotations["annotations"]["row_classification"]
                },
                image_name: this.image_name,
                field_name: this.field_name,
                field_json: this.field_json,
                not_done: true, // Don't mark row qa as done
            }

            // Navigate to next job.
            var next_idx = this.next_incomplete_subimage();

            // Prevent leave page warning
            this.ulabel.set_saved(true);

            if (next_idx >= this.n_subimages || this.leave_qa_cycle || this.submit_all_subimages) {
                // Add user to is_modified_by list
                mark_is_modified_by(this.field_json, this.image_name, this.user);

                // Await here bc navigating away from page can prevent the save
                hit_terraform_api(row_annotations_params, "save_row_qa");
                if (this.submit_all_subimages) {
                    let params = {
                        field_json: this.field_json,
                        update_items: [{
                            "image_name": this.image_name,
                            "qa_type": "ppa_qa",
                            "qa_val": "approved",
                        }], // Approve ppa_qa overall
                    }
                    for (let subimage_idx = 0; subimage_idx < this.n_subimages; subimage_idx++) {
                        params.update_items.push({
                            "image_name": this.image_name,
                            "qa_type": "keypoint_qa",
                            "qa_val": "approved",
                            "subimage_idx": subimage_idx,
                        }); // approve each subimage
                    }
                    await hit_terraform_api(params, "batch_update_qa");
                } else {
                    await hit_terraform_api(keypoint_post_annotations_params, "save_ppa_qa");
                }

                // Update thumbnails
                console.log(await ecs_create_single_image_thumbnails(this.field_json, this.image_name));

                // If everything occurred as expected, unlock 
                await unlock_image(this.field_json, this.image_name, this.user);

                // Apparently routing with the vue router
                // doesn't close Ulabel properly, so forcibly
                // redirect so that ulabel dies
                if (this.leave_qa_cycle) {
                    // Go back to progress
                    location.href = "/progress";
                } else {
                    // Continue qa
                    location.href = '/plant_count_qa';
                }


            } else {
                // Don't 'await' to speed up loading
                hit_terraform_api(keypoint_post_annotations_params, "save_ppa_qa");
                hit_terraform_api(row_annotations_params, "save_row_qa");

                // Add user to is_modified_by list
                mark_is_modified_by(this.field_json, this.image_name, this.user);

                // Update locally stored annotations
                this.annotations = annotations["annotations"]["plant_counting"];
                console.log("Annotations posted.");

                // Locally update qa_statuses
                this.qa_status[this.subimage_idx]["keypoint_qa"] = "approved";

                // navigate to next image
                this.subimage_idx = next_idx;
                this.$route.params.subimage_idx = next_idx; // Update global param for Navbar
                this.update_store(); // update in case of reject
                this.reload_ulabel();

                // Update thumbnails
                console.log(await ecs_create_single_image_thumbnails(this.field_json, this.image_name));
            }
        },
        next_incomplete_subimage() {
            // Return index of next not_done subimage, or return
            // the total number of subimages so that we progress
            // to the next qa job
            for (let next_idx = (this.subimage_idx + 1).valueOf(); next_idx < this.n_subimages; next_idx++) {
                if (this.qa_status[next_idx]["keypoint_qa"] == "not_done") {
                    console.log(this.subimage_idx, next_idx, this.qa_status)
                    return next_idx;
                }
            }
            return this.n_subimages;
        },
        preproc_annotations(annotations) {
            let anno_size = this.read_size_cookie();
            for (var i = 0; i < annotations.length; i++) {
                annotations[i].line_size = anno_size;
            }
            return annotations;
        },
        ulabel_bbox_delete() {
            return () => { };
        },
        startULabel() {
            var annos = this.preproc_annotations(this.annotations);

            let subimage_crop = get_subimage_crop_from_idx(
                this.n_w_subimages,
                this.n_h_subimages,
                this.image_width,
                this.image_height,
                this.subimage_idx,
                this.buffer_pct
            )
            let ulabel_initial_crop = get_ulabel_initial_crop_from_bbox(...subimage_crop);
            let subimage_anno = make_ulabel_bbox(...subimage_crop)
            let subtasks = {
                [ULABEL_SUBTASK_IDS.PLANT_COUNT_QA]: ppa_qa_subtask(annos),
                [ULABEL_SUBTASK_IDS.ROW_QA]: gendered_row_qa_subtask(
                    this.row_annotations,
                    {
                        inactive_opacity: 0.0
                    }
                ),
                [ULABEL_SUBTASK_IDS.SUBIMAGE_BOUNDS]: subimage_bounds_subtask([subimage_anno]),
            };
            var email = get_username(this);
            if (email === undefined) {
                email = "Unauthenticated MFStand User"
            }
            console.log("Username on ULabel start: " + email);

            let keypoint_confidence_slider_val = 30; // Default to 30
            let row_dist_slider_val = 50; // Default to 50

            if ("keypoint_slider_val" in this.image_tags) {
                keypoint_confidence_slider_val = parseInt(this.image_tags["keypoint_slider_val"]["value"]);
                console.log("Keypoint slider value found in image tags: " + keypoint_confidence_slider_val);
            } else if ("keypoint_confidence_slider_default_value" in this.field_tags) {
                keypoint_confidence_slider_val = parseInt(this.field_tags["keypoint_confidence_slider_default_value"]["value"]);
                console.log("Keypoint slider default value found in field tags: " + keypoint_confidence_slider_val);
            } else {
                console.log("No keypoint slider value saved, leaving as default: " + keypoint_confidence_slider_val);
            }

            // This is invariant to whether or not QA has been performed
            if ("row_dist_slider_val" in this.image_tags) {
                row_dist_slider_val = parseInt(this.image_tags["row_dist_slider_val"]["value"]);
                console.log("Row dist slider value found in image tags: " + row_dist_slider_val);
            } else if ("row_distance_slider_default_value" in this.field_tags) {
                row_dist_slider_val = parseInt(this.field_tags["row_distance_slider_default_value"]["value"]);
                console.log("Row dist slider default value found in field tags: " + row_dist_slider_val);
            } else {
                console.log("No row dist slider value saved, leaving as default: " + row_dist_slider_val);
            }

            // Config object
            let config_data = {
                "filter_low_confidence_default_value": keypoint_confidence_slider_val / 100,
                "keypoint_slider_default_value": keypoint_confidence_slider_val / 100,
                "filter_row_distance_default_value": row_dist_slider_val,
                "create_bbox_on_initial_crop": "" // Disable keybind
            };
            // TODO use config entirely programmatically 

            // Initial ULabel configuration
            let ulabel = new this.ulabel_variant(
                "container",          // container_id
                this.rgb_image_url,   // image_data
                email,                // username
                this.on_submit,       // on_submit
                subtasks,             // subtasks
                null,                 // task_meta
                null,                 // annotation_meta
                1,                    // px_per_px
                ulabel_initial_crop,  // initial_crop
                2,                    // Initial Line Size
                null,                 // instructions_url
                config_data,          // config data
            );
            console.log(ulabel.config);
            console.log(ulabel);

            this.ulabel = ulabel;

            let ff_fn = this.force_filter;

            // Wait for ULabel instance to finish initialization
            ulabel.init(function () {
                // ulabel.on(ulabel.config["done_callback"], () => {

                // })
                /*
                So this is absolutely horrible practice to deal with bonkers behavior 
                Basically the .on function in ulabel uses the functions .name property to reassign the callback
                For some reason vue-cli-service build sets all function.name properties to 'value'
                which breaks this callback behavior.
                Manually changing the property like this fixes it for the build version.
                */
                Object.defineProperties(
                    ulabel.finish_annotation,
                    {
                        name:
                        {
                            value: "finish_annotation",
                            writable: true,
                        }
                    }
                )
                // Delete within bbox hack
                ulabel.on(ulabel.finish_annotation, () => {
                    delete_annos_in_bbox(ulabel);
                    delete_annos_in_polygon(ulabel);
                });


                // Force filter on load via clicking the inc/dec buttons
                ff_fn();
                // }
            });

            this.enable_submit();
            // this.disable_submit();
        },
        force_filter() {
            console.log("Forcibly clicking filter buttons...");
            let inc_button = $(".button.inc.keypoint-slider-increment.keypoint-slider-button")[0];
            let dec_button = $(".button.dec.keypoint-slider-increment.keypoint-slider-button")[0];
            console.log(inc_button);
            console.log(dec_button);
            inc_button.click();
            dec_button.click();
        },
        disable_submit() {
            // Hack to disallow submit before dirtying the page
            document.getElementById("submit").removeAttribute("href");
        },
        enable_submit() {
            // Hack to allow submit before dirtying the page
            // Ensure submit button is always pressable
            var observer = new MutationObserver(function (mutations) {
                mutations.forEach(function (mutation) {
                    if (mutation.type === "attributes") {
                        // When button is modified, make sure it can still be pressed
                        if (!document.getElementById("submit").hasAttribute("href")) {
                            document.getElementById("submit").href = "#";
                        }
                    }
                });
            });
            observer.observe(document.getElementById("submit"), {
                attributes: true //configure it to listen to attribute changes
            });
        }
    },
};
</script>

<style>
#container #toolbox div.keypoint-slider div.keypoint-slider-holder {
    padding: 0;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.5em;
}

#container #toolbox div.keypoint-slider div.keypoint-slider-holder span.increment {
    bottom: 0.5em;
}
</style>