<template>
    <div>
        <h2>Process</h2>

        <!-- Binary Set Selection -->
        <form v-on:submit.prevent="selectBinarySet">
            <label>Binary Selector &nbsp;</label>
            <select v-model="selected_binary_set" value="None" required>
                <option disabled value>--Select Binary Set--</option>
                <option v-for="binary_set in binary_sets" :key="binary_set">
                    {{ binary_set }}
                </option>
            </select>
            <br/>
            <input type="submit" name="select" value="Select Binary Set" />
            <br/>
        </form>
        <label>Current binary set: {{ binary_set }}</label>
        <br/><br/>
        <!-- Outsourcing Marker Checkbox -->
        <form v-on:submit.prevent="setOutsourcingMarker">
            <input type="checkbox" id="outsourcing_marker" name="outsourcing_marker" :value="outsourced_flag" v-model="outsourced_flag">
            <label for="outsourcing_marker">&nbsp; Outsourcing Marker</label>
            <br>
            <input type="submit" name="select" value="Update Outsourcing Marker" />
            <br/>
        </form>


        <form v-on:submit.prevent="runProcess()">
            <fieldset>
                <legend>New Row Detection</legend>
                <input type="radio" id="mf_dt_row_det" name="run_type">
                <label for="mf_dt_row_det"> DT Row Detection</label>
                <br>
            </fieldset>
            <br>
            <fieldset>
                <legend>Binary Generation</legend>
                <input type="radio" id="multi_binary_generation" name="run_type">
                <label for="multi_binary_generation"> Multi-Binary Generation</label>
                <br>
            </fieldset>
            <!-- <input type="radio" id="sv_row_det" name="run_type">
            <label for="sv_row_det"> Row Detection (Single Variety)</label>
            <br> -->
            <br>
            <input type="submit" value="Submit">
        </form>
    </div>
</template>
<script>
import { ecs_create_bin_thumbnails } from "@common/app/api_endpoints.js";
import { hit_terraform_api } from "@common/app/api_utils.js";
export default {
    data: function() {
        return {
            message: "",
            field_json: null,
            field_name: "",
            selected_binary_set: "",
            binary_set: "",
            binary_sets: [],
            outsourced_flag: false,
        }
    },
    created() {
        this.field_json = this.$store.state.$field_json;
        this.field_name = this.$store.state.$field_name;
        this.binary_set = this.$store.state.$binary_set;
        console.log(this.field_name);
    },
    async mounted() {
        this.getBinarySets();
        this.getOutsourcingMarker();
    },
    methods: {
        async getBinarySets () {
            if (this.field_json != "") {
                var params = {
                    field_json: this.field_json, 
                };
                console.log(params)
                var data = await hit_terraform_api(params, "get_binary_sets");
                for (let binary_set of data.data) {
                    if (binary_set.split("_").slice(-1) != "thumbnails") {
                        this.binary_sets.push(binary_set);
                    }
                }
                console.log(this.binary_sets)
            }
        },

        async selectBinarySet() {
            this.$store.commit("update$binary_set", this.selected_binary_set);
            this.binary_set = this.selected_binary_set;

            // create binary thumbnails
            console.log(await ecs_create_bin_thumbnails(this.field_json, "binaries/" + this.binary_set));

            // Add field_tag to ddb
            var params = {
                field_json: this.field_json,
                set_name: this.binary_set,
            }

            var data = await hit_terraform_api(params, "add_binary_field_tag");
            console.log(data);
        },
        async getOutsourcingMarker() {
            var params = {
                field_json: this.field_json,
            }
            var data = await hit_terraform_api(params, "get_all_field_tags");
            console.log(data);
            if ("outsourced" in data.data) {
                this.outsourced_flag = data.data["outsourced"]["value"];
            } else {
                this.outsourced_flag = false;
            }
        },
        async setOutsourcingMarker() { 
            var params = {
                field_json: this.field_json,
                tag_name: "outsourced",
                tag_dict: {
                    "value": this.outsourced_flag,
                }
            }
            console.log(params)
            var data = await hit_terraform_api(params, "set_field_tag");
            console.log(data);
            hit_terraform_api(params, "gen_all_outsourced_fields")
            hit_terraform_api(params, "gen_all_field_table_data")
        },

        async runProcess() {
            try {
                var run_type = document.querySelector('input[name="run_type"]:checked').id;
                var params, data, endpoint_str;
                console.log(run_type);
                params = {
                    "field_json": this.field_json,
                }
                switch (run_type) {
                    case "mf_dt_row_det":
                        endpoint_str = "ecs_dt_row_detection"
                        break
                    case "multi_binary_generation":
                        endpoint_str = "ecs_multi_binary_generation"
                        break
                    default:
                        alert("Please select a process to run");
                        return;
                }
                data = await hit_terraform_api(params, endpoint_str);
                alert(data.data);
                return
            }
            catch (error) {
                alert("Failed to start process.");
                console.log(error)
            }
        },
    },
}
</script>
<style scoped>
    fieldset {
        border: 1px solid rgb(0, 0, 0);
        padding: 0.35em 0.625em 0.75em;
        margin: 0 2px 0.5em 0;
        width: 30em;
        display: inline-block;
    }
    legend {
        padding: 0.3em;
        width: auto;
        margin-left: 5px;
        margin-right: 5px;
        text-align: left;
    }
</style>