// Utility functions for QA jobs
import { hit_terraform_api } from "./api_utils.js";
import { get_all_image_tags } from "./api_endpoints.js";

export async function lock_image(field_json, image_name, user) {
    console.log("L O C K it");
    let params = {
        field_json: field_json,
        update_items: [{
            "image_name": image_name,
            "qa_type": "IS_LOCKED",
            "qa_val": true,
            "locked_by": user,
        }],
        check_lock_cond: true,
    };
    try {
        let response = await hit_terraform_api(params, "batch_update_qa");
        return response.status == 200
    }
    catch {
        return false;
    }
}

export async function unlock_image(field_json, image_name, user) { 
    console.log("U N L O C K it");
    let params = {
        field_json: field_json,
        update_items: [{
            "image_name": image_name,
            "qa_type": "IS_LOCKED", 
            "qa_val": false,
            "locked_by": user,
        }],
    };
    await hit_terraform_api(params, "batch_update_qa");
}

export async function mark_is_modified_by(field_json, image_name, user, image_tags=null) {  
    
    if (image_tags == null) {
        image_tags = await get_all_image_tags(field_json);
    }

    let params = {
        field_json: field_json,
        image_name: image_name,
        tag_name: "is_modified_by",
    };
        
    // When tag exists, we append our user to the end of the list, and cast it to a set
    // else we create a new list.
    if (image_name in image_tags && "is_modified_by" in image_tags[image_name]) {
        if (user != image_tags[image_name]["is_modified_by"].slice(-1)) {
            params.tag_dict = image_tags[image_name]["is_modified_by"].concat(user);
        } else {
            console.log("Avoid adding duplicate most recent user.");
            return;
        }
    } else {
        params.tag_dict = [user];
    }
    hit_terraform_api(params, "add_image_tag");
}

/**
 * Util to truncate a full email address down to
 * the first name and last name first initial
 * 
 * @param {String} user full username (email address)
 * @returns {String} truncated username, ie trevor.b
 */
export function truncate_username(user) {
    return user.replace(/(.*)\.(.).+@.+/, "$1.$2");
}

export function get_username(page) {
    try {
        return page.$parent.user.attributes.email;
    } catch(e) {
        return page.$store.state.$user;
    }
    
}