<template>
    <div>
        <div id='leaderboard'>
            <!-- <input type="button" value="Update Leaderboard" v-on:click="update_leaderboard"/><br/> -->
            <h>Today's Rankings</h>
            <div v-for="([qa_name, img_count], idx) in ordered_today_data" :key="idx">
                {{qa_name}}: {{img_count}}
            </div>
            <!-- <table style="margin-left: auto; margin-right: auto;">
                <td>
                    <h>All-Time Leaderboard</h>
                    <div v-for="([qa_name, img_count], idx) in ordered_all_time_data" :key="idx">
                        {{qa_name}}: {{img_count}}
                    </div>
                </td>
                
                <td>
                    <h>Today's Rankings</h>
                    <div v-for="([qa_name, img_count], idx) in ordered_today_data" :key="idx">
                        {{qa_name}}: {{img_count}}
                    </div>
                </td>
            </table> -->
        </div>
    </div>
</template>
<script>
import { hit_terraform_api } from "@common/app/api_utils.js"

export default {
    data() {
        return {
            all_time_data: {},
            today_data: {},
            ordered_all_time_data: [],
            ordered_today_data: [],
        }
    },
    created() {
        },
    async mounted(){
        this.populate_leaderboard();
    },
    methods: {
        // async update_leaderboard() {
        //     alert("Updating leaderboard (this may take a while...)");
        //     let response = await hit_terraform_api({}, "update_leaderboard");
        //     console.log(response);
        //     this.populate_leaderboard();
        // },

        async populate_leaderboard() {
            // hit_terraform_api({}, "update_leaderboard");
            var data = await hit_terraform_api({}, "get_leaderboard");
            data = data.data;
            this.all_time_data = data["all_time_image_count"];
            this.today_data = data["today_image_count"];
            console.log(this.today_data)
            
            // for(var qa_name in this.all_time_data){
            //     this.ordered_all_time_data.push([qa_name, this.all_time_data[qa_name]]);
            // }

            // this.ordered_all_time_data = this.ordered_all_time_data.sort(function(a, b) {
            //     return b[1] - a[1];
            // }.bind(this));

            for (let qa_name in this.today_data){
                this.ordered_today_data.push([qa_name, this.today_data[qa_name]]);
            }

            this.ordered_today_data = this.ordered_today_data.sort(function(a, b) {
                return b[1] - a[1];
            }.bind(this));
        },
    } 
}
</script>
