// ULabel Subtask Utilities
import { ULABEL_SUBTASK_IDS, ULABEL_SUBTASK_DISPLAY_NAMES } from "./constants"

export function ppa_qa_subtask(resume_from_annos, args) { 
    return { 
        display_name: ULABEL_SUBTASK_DISPLAY_NAMES[ULABEL_SUBTASK_IDS.PLANT_COUNT_QA], 
        classes: [ 
            {
                name: "Plant",
                color: "yellow",
                id: 1,
            }
        ],
        // Deletion modes should probably be a flag
        allowed_modes: ["point", "bbox", "polygon"],
        resume_from: resume_from_annos,
        task_meta: null,
        annotation_meta: null,
        ...args
    }
}

export function gendered_row_qa_subtask(resume_from_annos, args) {
    return {
        display_name: ULABEL_SUBTASK_DISPLAY_NAMES[ULABEL_SUBTASK_IDS.ROW_QA],
        classes: [
            {
                name: "Male",
                color: "blue",
                id: 10,
            },
            {
                name: "Female",
                color: "white",
                id: 11,
            },
        ],
        allowed_modes: ["polyline"],
        resume_from: resume_from_annos,
        task_meta: null,
        annotation_meta: null,
        ...args
    }
}

export function subimage_bounds_subtask(resume_from_annos, args) {
    return {
        display_name: ULABEL_SUBTASK_DISPLAY_NAMES[ULABEL_SUBTASK_IDS.SUBIMAGE_BOUNDS],
        classes: [
            {
                name: "Subimage Bound",
                color: "black",
                id: 1,
            },
            {
                name: "Rejected Subimage Bound",
                color: "red",
                id: 2,
            },
        ],
        allowed_modes: ["bbox"],
        resume_from: resume_from_annos,
        task_meta: null,
        annotation_meta: null,
        ...args
    }
}