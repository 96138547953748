<template>
    <div>
        <div
            id="container"
            style="
                width: 100%;
                height: calc(100vh - 66px);
                position: absolute;
                top: 66px;
                left: 0;
            "
        >
        </div>
    </div>
</template>

<script>
import { get_next_image_to_qa } from "@common/app/api_endpoints.js";
import { get_username } from "@common/app/qa_utils.js";

export default {
    created() {
        // Immediately redirect to next qa task
        this.getNextImageToQA();
    },

    methods: {
        async getNextImageToQA() {
            let next_image = await get_next_image_to_qa(
                this.$store.state.$field_json,
                "",
                "keypoint_qa", // used for both keypoint/row qa
                get_username(this),
            )
            console.log(next_image);

            if (next_image == "Nothing to QA.") {
                // Return to progress
                location.href = "/progress";
            } else if ("subimage_idx" in next_image) {
                // Start keypoint qa
                this.$router.push({
                    name: "PPA QA",
                    params: {
                        field_name: this.$store.state.$field_name,
                        image_name: next_image.image_name,
                        subimage_idx: next_image.subimage_idx,
                        n_h_subimages: 3,
                        n_w_subimages: 3,
                    },
                });
            } else {
                // Row QA
                this.$router.push({
                    name: "Row QA",
                    params: {
                        field_name: this.$store.state.$field_name,
                        image_name: next_image.image_name,
                    },
                });
            }
        }
    }
};
</script>