<template>
    <div>
        <div id='map-parent'>
            <div id='map'></div>
        </div>
        
        <link rel="stylesheet" href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
            integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
            crossorigin=""/>

    </div>
</template>
<script>
import leaflet from 'leaflet';
import { cache_load_all_url_lat_lons } from "@common/app/cache_manager.js"
import { get_all_image_tags, get_all_rgb_urls, get_all_rgb_filenames } from "@common/app/api_endpoints.js"

export default {
    data() {
        return {
            field_json: "",
            field_name: "",
            progress_data: null,
            urls: null,
            tags: null, 
            images: null,
            markers: [], 
        }
    },
    created() {
    },
    async mounted(){
        this.field_json = this.$store.state.$field_json;
        this.field_name = this.$store.state.$field_name;
        this.tags = await get_all_image_tags(this.field_json);
        this.urls = await get_all_rgb_urls(this.field_json);
        this.images = await get_all_rgb_filenames(this.field_json);
        var coords = await cache_load_all_url_lat_lons(this.field_json, this.tags, this.urls, this.images);
        console.log(coords);

        var lats = Object.entries(coords).map(el => el[1].lat);
        var avg_lat = lats.reduce((a,c) => a + c) / lats.length;

        var lons = Object.entries(coords).map(el => el[1].lon);
        var avg_lon = lons.reduce((a,c) => a + c) / lons.length;

        var map = leaflet.map('map').setView([avg_lat, avg_lon], 15);
        leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '© OpenStreetMap'
        }).addTo(map);

        for(var i = 0; i < coords.length; i++) {
            var lat = coords[i].lat;
            var lon = coords[i].lon;
            var url = this.urls[i];
            var marker = leaflet.marker([lat, lon]).addTo(map);
            marker.bindPopup(this.make_popup_div(url));
        }
    },
    methods: {
        test() {
            console.log("test");
        },
        make_popup_div(url) {
            const img_tag = "<img src=" + url + " class=map-popup >";
            const rej_button = `<button class=reject-button onclick="alert('yuh')">Reject</button>`;
            return "<div>" + img_tag + rej_button + "</div>";
        }
    } 
}
</script>

<style>
.map-popup {
    width: 25em;
    height: 25em;
}
#map-parent {
    height: 80vh;
    width: 100vw;
    position: relative;
}
#map {
    margin: 1.5em auto;
    display: flex;
    text-align: center;
    border: .25em solid black;
    outline: none;
    overflow: hidden;
    min-height: 100%;
    min-width: 100%;
}

/* .leaflet-control-container {
    display: none;
} */

</style>
