/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import ErrorComponent from '@/components/Error';
import UploadComponent from '@/components/Upload';
import SelectFieldComponent from '@/components/SelectField';
import PlantCountQA from '@/components/PlantCountQA';
import Process from '@/components/Process';
import RowQA from '@/components/RowQA';
import Progress from '@/components/Progress';
import Map from '@/components/Map';
import Grid from '@/components/GridViewer';
import Leaderboard from '@/components/Leaderboard';
import Export from '@/components/Export';
import RejectPPA from '@/components/RejectPPA';
import RejectRow from '@/components/RejectRow';
import PlantOrRowQA from '@/components/PlantOrRowQA';
import GenderQA from '@/components/GenderQA';
import FieldOverview from '@/components/FieldOverview';

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
        },
        {
            path: '/error', component: ErrorComponent
        },
        {
            path: '/upload',
            name: "Upload",
            component: UploadComponent
        },
        {
            path: '/select_field',
            name: "Select Field",
            component: SelectFieldComponent,
        },
        {
            path: '/plant_count_qa',
            name: "PPA QA",
            component: PlantCountQA,
        },
        {
            path: '/rejectPPA',
            name: "RejectPPA",
            component: RejectPPA
        },
        {
            path: '/rejectRow',
            name: "RejectRow",
            component: RejectRow
        },
        {
            path: '/process', component: Process
        },
        {
            path: '/row_qa', 
            name: "Row QA", 
            component: RowQA, 
        },
        {
            path: '/progress', 
            name: "Progress",
            component: Progress
        },
        {
            path: '/map', 
            name: "Map",
            component: Map
        },
        {
            path: '/grid',
            name: "Grid",
            component: Grid
        },
        {
            path: '/leaderboard',
            name: "Leaderboard",
            component: Leaderboard
        },
        {
            path: '/export', component: Export
        },
        // Leaving this route out so it 404s for now
        // {
        //     path: '/plant_or_row_qa', 
        //     name: "Plant Or Row QA",
        //     component: PlantOrRowQA,
        // },
        {
            path: '/gender_qa', 
            name: "Gender QA",
            component: GenderQA,
        },
        {
            path: '/field_overview',
            name: "Field Overview",
            component: FieldOverview
        }

    ],
    // scrollBehavior(to, from, savedPosition) {
    //     console.log(savedPosition);
    //     console.log(to.name);
    //     if (to.name == "Progress") {
    //         console.log("Setting Progress Scroll...")
    //         return { 
    //             top: 1000
    //         };
    //     };
    // },
})
