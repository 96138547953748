<template>
    <div class="container" style="margin: 0; text-align: left">
        <h2>Field JSONs</h2>
        <form v-on:submit.prevent="uploadFiles">
            <input
                ref="files"
                type="file"
                accept=".json"
                name="select_files"
                multiple
                @change="handleFilesUpload"
            />
            <input type="submit" name="upload" value="Upload!" />
        </form>
        <br>
        <!-- Display fields that are in the db -->
        <ul id="field_json_list">
            <li v-for="name in field_names" :key="name">
                {{ name }}
            </li>
        </ul>
        <br />
    </div>
</template>
<script>
import { ecs_create_rgb_thumbnails, ecs_create_bin_thumbnails, ecs_create_row_thumbnails } from "@common/app/api_endpoints";
import { hit_terraform_api } from '@common/app/api_utils';

export default {
    data: function () {
        return {
            message: "",
            files: [],
            field_names: [],
        };
    },

    mounted() {
        this.getFieldList();
    },

    methods: {
        /**
         * Mount user-uploaded files to the "files" attribute of the page.
         *
         * @param {*} event Trigger event passthrough.
         */
        async handleFilesUpload(event) {
            for (var i = 0; i < event.target.files.length; i++) {
                var file = event.target.files[i];
                this.files[i] = JSON.parse(await this.blobToData(file));
            }
        },

        /**
         * Read file content from file/blob object as text
         *
         * @param {object} blob file/blob object
         * @return {object} Promise; file content loaded as text.
         */
        async blobToData(blob) {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsText(blob);
            });
        },
        /**
         * Upload all files currently stored in the page "files" attribute
         * to the dynamo-db table "mfstand-qa-field-jsons" and refresh the
         * field list on the page
         */
        async uploadFiles(){
            // Add all the files
            var formData = new FormData();
            for(var i=0; i<this.files.length; i++){
                var file = this.files[i];
                formData["files[" + i + "]"] = file;
                // indexed kinda weird, using formData["files[i]"]

                console.log(file["field_name"]);
            }
            console.log("Uploading...");
            // Be sure to stringify so that CORS permisisons can get attached properly
            console.log(await hit_terraform_api(JSON.stringify(formData), "upload_fields"));

            // Update field list
            this.getFieldList();
        },
        /**
         * Get the field names currently stored on ddb and add them to the
         * page's field_names attribute.
         */
        async getFieldList() {
            var field_list = await hit_terraform_api(null, "get_field_list");
            this.field_names = field_list.data.field_names;
            console.log("Number of fields:")
            console.log(this.field_names.length);
            this.field_names.sort((a, b) => { return a.localeCompare(b)})
        },
    },
};
</script>
