<template>
    <div>
        <!--save button, floating-->
        <div class="row">
            <div class="save-changes">
                <button class="btn btn-primary" @click="save_changes">Save Changes</button>
            </div>
        </div>
        <div id="grid-container"></div>
    </div>
</template>

<script>
import { hit_terraform_api } from '@common/app/api_utils';
import { Grid, html } from 'gridjs';
import "gridjs/dist/theme/mermaid.css"
import * as JQuery from "jquery";
const $ = JQuery.default;
// import { html } from 'gridjs';

export default {
    data: function () {
        return {
            column_names: [],
            data_rows: [],
            grid: new Grid(),
            // OG values, first key is display_name, second key is field_group#ddb_field_name
            og_values: {},
            /**
             * To add a new value:
             * (1) Add a value to the generation of `all_field_table_data` in 
             * `terraform_backend/lambdas/ddb_cache_generators.py#gen_all_field_table_data
             * (2) Add a new entry to `column_map`.
             * (3) If editable, add the respective `tag_name` to the `ddb_tags` list.
             * (4) Add the necessary html classes to the column map, and a tag resolution function 
             * to extract the tag value if necessary.
             * (5) Add a formatter (or sort function) within `load_field_data`, and attach window functions if necessary.
             * (6) Add column to the `editable_cols` list at the end of `load_field_data`.
             */
            column_map: {
                DEFAULT: {
                    display_name: 'Default',
                    editable: false,
                    col_idx: -1,
                    input_html_class: '',
                    cell_html_class: '',
                    ddb_tags: []  // Items may contain multiple tags
                },
                FIELD_NAME: {
                    display_name: 'Field Name',
                },
                FIELD_GROUP: {
                    display_name: 'Field Group',
                },
                OUTSOURCED: {
                    display_name: 'Outsourced',
                    editable: true,
                    input_html_class: 'outsourced-checkbox',
                    cell_html_class: 'outsourced-cell',
                    ddb_tags: [ 
                        {
                            tag_name: 'outsourced',
                            resolution_fn: (val) => {
                                return val.split('#')[0] == this.OUTSOURCED_VALS.YES;
                            },
                        },
                        {
                            tag_name: 'outsourcing_priority',
                            resolution_fn: (val) => {
                                return parseInt(val.split('#')[1]);
                            },
                        }
                    ],
                },
                DUE_DATE: {
                    display_name: 'Due Date',
                    editable: true,
                    input_html_class: 'due-date-input',
                    cell_html_class: 'due-date-cell',
                    ddb_tags: [
                        {
                            tag_name: 'due_date',
                        }
                    ]
                },
                BILLED_DATE: {
                    display_name: 'Billed Date',
                    editable: true,
                    input_html_class: 'billed-date-input',
                    cell_html_class: 'billed-date-cell',
                    ddb_tags: [
                        {
                            tag_name: 'billed_date',
                        }
                    ]
                },
                SUBMISSION_DATE: {
                    display_name: 'Submission Date',
                },
                FIELD_PROGRESS: {
                    display_name: 'Field Progress',
                    cell_html_class: 'field-progress-cell'
                },
                IMAGE_BUFFER: {
                    display_name: 'Image Buffer (%)',
                    editable: true,
                    input_html_class: 'image-buffer-input',
                    cell_html_class: 'image-buffer-cell',
                    ddb_tags: [
                        {
                            tag_name: 'image_buffer',
                        }
                    ]
                },
                KEYPOINT_CONFIDENCE_SLIDER_DEFAULT_VALUE: {
                    display_name: 'Keypoint Confidence Slider Default Value',
                    editable: true,
                    input_html_class: 'keypoint-confidence-slider-default-value-input',
                    cell_html_class: 'keypoint-confidence-slider-default-value-cell',
                    ddb_tags: [
                        {
                            tag_name: 'keypoint_confidence_slider_default_value',
                        }
                    ]
                },
                ROW_DISTANCE_FILTER_STATUS: {
                    display_name: 'Row Distance Filter Active/Default Value',
                    editable: true,
                    input_html_class: 'row-distance-filter-status-input',
                    cell_html_class: 'row-distance-filter-status-cell',
                    ddb_tags: [
                        {
                            tag_name: 'row_distance_filter_active',
                            resolution_fn: (val) => {
                                return val.split('#')[0] == this.CHECKBOX_VALS.CHECKED;
                            },
                        },
                        {
                            tag_name: 'row_distance_slider_default_value',
                            resolution_fn: (val) => {
                                return parseInt(val.split('#')[1]);
                            },
                        },
                    ]
                },
            },
            OUTSOURCED_VALS: {
                YES: "True",
                NO: "False"
            },
            CHECKBOX_VALS: {
                CHECKED: "True",
                NOT_CHECKED: "False"
            }
        }
    },
    created() {
        for (let col_entry in this.column_map) {
            // If entries aren't present, copy from column_map.DEFAULT
            for (let default_entry in this.column_map.DEFAULT) {
                if (this.column_map[col_entry][default_entry] == undefined) {
                    this.column_map[col_entry][default_entry] = this.column_map.DEFAULT[default_entry];
                }
            }
        }

    },
    mounted() {
        this.load_field_data().then(() => {
            console.log(this.column_names)
            this.grid.updateConfig({
                columns: this.column_names,
                data: this.data_rows,
                sort: true,
                search: true,
                pagination: {
                    limit: 10
                },
                // height: '75vh',
                fixedHeader: true,
                autoWidth: true,
                resizable: true,
            })
            this.grid.render(document.getElementById('grid-container'));
        });
    },
    methods: {
        /**
         * Sort dates in MM-DD-YYYY format 
         * @param {*} a 
         * @param {*} b 
         */
        sort_dates(a, b) {
            if (a == b) {
                return 0;
            }
            // Handle empty strings
            if (a == '') {
                return 1;
            } else if (b == '') {
                return -1;
            }

            let a_split = a.split('-');
            let b_split = b.split('-');
            let a_date = new Date(a_split[2], a_split[0] - 1, a_split[1]);
            let b_date = new Date(b_split[2], b_split[0] - 1, b_split[1]);
            if (a_date > b_date) {
                return -1;
            }
            return 1;
        },
        /**
         * Build html fstr from attributes object
         */
        build_html_attrs_fstr(attrs) {
            let fstr = '';
            for (let key in attrs) {
                fstr += `${key}="${attrs[key]}" `
            }
            return fstr;
        },
        /**
         * Get the associated data row given the html selector
         * @param {*} html_input HTML selector to target
         * @param {*} col_map_item Item within column map
         */
        get_data_row_from_input(html_input) {
            let ddb_field_name = html_input.getAttribute('ddb_field_name');
            let field_group = html_input.getAttribute('field_group');
            return this.data_rows.find(row => {
                return row[this.column_map.FIELD_NAME.col_idx] == ddb_field_name &&
                    row[this.column_map.FIELD_GROUP.col_idx] == field_group;
            });
        },
        /**
         * Highlight html element if the value has changed 
         * @param {*} html_el HTML element to target
         * @param {*} data_row Data row to select from
         * @param {*} col_map_item Item within column map
         */
        highlight_if_changed(html_el, data_row, col_map_item) {
            if (data_row[col_map_item.col_idx] != this.get_og_value(data_row, col_map_item.display_name)) {
                html_el.style.backgroundColor = '#ffff99';
            } else {
                html_el.style.backgroundColor = '#ffffff';
            }
        },
        /**
         * Select the input element for a given row and column map item 
         * @param {*} data_row Data row to select from
         * @param {*} col_map_item Column map item to select from
         */
        select_col_input(data_row, col_map_item) {
            let field_group = data_row[this.column_map.FIELD_GROUP.col_idx];
            let ddb_field_name = data_row[this.column_map.FIELD_NAME.col_idx];
            let input_selector = `input[ddb_field_name="${ddb_field_name}"][field_group="${field_group}"].${col_map_item.input_html_class}`;
            return document.querySelector(input_selector);
        },
        /**
         * Clear the highlight for a cell
         * @param {*} data_row Data row to select from
         * @param {*} col_map_item Column map item to select from
         */
        clear_highlight(data_row, col_map_item) {
            let input_el = this.select_col_input(data_row, col_map_item);
            if (input_el) {
                input_el.parentElement.style.backgroundColor = 'white';
            }
        },
        /**
         * Save the changes made to an input element to DDB
         * @param {*} data_row Data row to select from
         * @param {*} col_map_item Column map item to select from
         */
        save_input_changes_to_ddb(data_row, col_map_item) { 
            let og_val = this.get_og_value(data_row, col_map_item.display_name);
            let input_val = data_row[col_map_item.col_idx];
            if (input_val != og_val) {
                for (let tag_entry of col_map_item.ddb_tags) { 
                    if (!('resolution_fn' in tag_entry)) { 
                        tag_entry.resolution_fn = (val) => { return val; }
                    }
                    // Update terraform 
                    let tag_params = { 
                        field_name: data_row[this.column_map.FIELD_NAME.col_idx],
                        field_group: data_row[this.column_map.FIELD_GROUP.col_idx],
                        tag_name: tag_entry.tag_name,
                        tag_dict: { 
                            "value": tag_entry.resolution_fn(input_val)
                        }
                    }
                    console.log(tag_params)
                    hit_terraform_api(tag_params, "set_field_tag_with_ddb_field_name")
                }

                this.set_og_value(data_row, col_map_item.display_name, input_val);
                this.clear_grid_cache();
                this.clear_highlight(data_row, col_map_item);
            }
        },
        /**
         * Get the base html attributes for a table cell and it's child  
         * @param {*} cell Table cell
         * @param {*} grid_row Grid table row 
         * @param {*} col_map_item Column map item
         * @returns [input_attrs, cell_attrs]
         */
        get_base_table_attrs(cell, grid_row, col_map_item) {
            let row_id = grid_row.id;
            let field_group = grid_row.cells[this.column_map.FIELD_GROUP.col_idx].data;
            let ddb_field_name = grid_row.cells[this.column_map.FIELD_NAME.col_idx].data;
            // Can't use the utilities as this is a grid_row and not a data_row
            let og_key = `${field_group}#${ddb_field_name}`;
            let input_attrs = {
                'ddb_field_name': ddb_field_name,
                'field_group': field_group,
                'row_id': row_id,
                'class': col_map_item.input_html_class,
            }
            let cell_attrs = {
                'class': col_map_item.cell_html_class,
                'style': '',
            }
            if (col_map_item.editable) {
                let edited = this.og_values[col_map_item.display_name][og_key] != cell;
                if (edited) {
                    cell_attrs['style'] += 'background-color: #ffff99;';
                }
            }
            return [
                input_attrs,
                cell_attrs
            ];
        },
        async load_field_data() {
            // Make modify_outsourced_tracker available to window object
            window.toggle_outsourced = this.toggle_outsourced;
            window.change_outsourcing_priority = this.change_outsourcing_priority;
            window.update_due_date = this.update_due_date;
            window.update_billed_date = this.update_billed_date;
            window.update_image_buffer = this.update_image_buffer;
            window.update_keypoint_confidence = this.update_keypoint_confidence;
            window.toggle_row_distance_filter = this.toggle_row_distance_filter;
            window.update_row_distance_filter_value = this.update_row_distance_filter_value;

            let params = {
                'item_key': 'all_field_table_data.json',
                'generation_lambda_name': 'gen_all_field_table_data',
            }
            let field_data = await hit_terraform_api(
                params,
                "get_ddb_cache_item"
            )
            this.column_names = field_data.data.columns;

            for (let col_entry in this.column_map) {
                let display_name = this.column_map[col_entry].display_name;
                this.column_map[col_entry].col_idx = this.column_names.indexOf(display_name);
            }
            this.column_names[this.column_map.OUTSOURCED.col_idx] = {
                name: this.column_map.OUTSOURCED.display_name,
                formatter: (cell, row) => {
                    let [checkbox_attrs, cell_attrs] = this.get_base_table_attrs(cell, row, this.column_map.OUTSOURCED);
                    // Copy checkbox_attrs to sliderbar_attrs
                    let sliderbar_attrs = {...checkbox_attrs};
                    checkbox_attrs['onclick'] = 'toggle_outsourced(this)';
                    cell_attrs['style'] += 'display: flex; flex-direction: column; justify-content: center;';

                    let [is_outsourced_str, outsourcing_priority] = cell.split('#');
                    sliderbar_attrs = {
                        ...sliderbar_attrs,
                        'type': 'range',
                        'min': 1,
                        'max': 5,
                        'value': outsourcing_priority,
                        'style': 'display: none;',
                    }
                    sliderbar_attrs['class'] = 'outsourcing-priority-slider';
                    sliderbar_attrs['onchange'] = 'change_outsourcing_priority(this)';
                    let outsourced = is_outsourced_str == this.OUTSOURCED_VALS.YES; 
                    if (outsourced) {
                        checkbox_attrs['checked'] = true;
                        sliderbar_attrs['style'] = '';
                    }

                    // Insert cell_attributes into html
                    let checkbox_html_attrs = this.build_html_attrs_fstr(checkbox_attrs);
                    let cell_html_attrs = this.build_html_attrs_fstr(cell_attrs);
                    let sliderbar_html_attrs = this.build_html_attrs_fstr(sliderbar_attrs);
                    return html(`<div ${cell_html_attrs}><input type="checkbox" ${checkbox_html_attrs}><input ${sliderbar_html_attrs}></div>`)
                },
                sort: {
                    compare: (a, b) => { 
                        let [a_is_outsourced_str, a_outsourcing_priority] = a.split('#');
                        let [b_is_outsourced_str, b_outsourcing_priority] = b.split('#');
                        if (a_is_outsourced_str == b_is_outsourced_str) {
                            return Math.sign(b_outsourcing_priority - a_outsourcing_priority);
                        }
                        if (a_is_outsourced_str == this.OUTSOURCED_VALS.YES) {
                            return -1;
                        }
                        return 1;
                    }
                }
            }
            this.column_names[this.column_map.DUE_DATE.col_idx] = {
                name: this.column_map.DUE_DATE.display_name,
                formatter: (cell, row) => {
                    let [due_date_selector_attrs, cell_attrs] = this.get_base_table_attrs(cell, row, this.column_map.DUE_DATE);
                    due_date_selector_attrs['onchange'] = 'update_due_date(this)';
                    due_date_selector_attrs['value'] = cell;

                    // Insert attrs into html
                    let due_date_selector_html_attrs = this.build_html_attrs_fstr(due_date_selector_attrs);
                    let cell_html_attrs = this.build_html_attrs_fstr(cell_attrs);
                    return html(`<div ${cell_html_attrs}><input type="date" ${due_date_selector_html_attrs}></div>`)
                },
                width: '150px',
            }
            this.column_names[this.column_map.BILLED_DATE.col_idx] = {
                name: this.column_map.BILLED_DATE.display_name,
                formatter: (cell, row) => {
                    let [billed_date_selector_attrs, cell_attrs] = this.get_base_table_attrs(cell, row, this.column_map.BILLED_DATE);
                    billed_date_selector_attrs['onchange'] = 'update_billed_date(this)';
                    billed_date_selector_attrs['value'] = cell;

                    // Insert attrs into html
                    let billed_date_html_attrs = this.build_html_attrs_fstr(billed_date_selector_attrs);
                    let cell_html_attrs = this.build_html_attrs_fstr(cell_attrs);
                    return html(`<div ${cell_html_attrs}><input type="month" ${billed_date_html_attrs}></div>`)
                },
                width: '150px',
            }
            this.column_names[this.column_map.IMAGE_BUFFER.col_idx] = { 
                name: this.column_map.IMAGE_BUFFER.display_name,
                formatter: (cell, row) => { 
                    let [image_buffer_slider_attrs, cell_attrs] = this.get_base_table_attrs(cell, row, this.column_map.IMAGE_BUFFER);
                    // Image buffer slider from 0 to 30
                    image_buffer_slider_attrs = {
                        ...image_buffer_slider_attrs,
                        'type': 'range',
                        'min': 0,
                        'max': 30,
                        'value': cell,
                        'onchange': 'update_image_buffer(this)',
                        'oninput': 'update_image_buffer(this)'
                    }
                    // TODO make these slider attrs a util
                    cell_attrs['style'] += 'display: flex; justify-content: space-between; width: 100%; padding: 0.7rem; align-items: center;';
                    // Insert attrs into html
                    let image_buffer_slider_html_attrs = this.build_html_attrs_fstr(image_buffer_slider_attrs);
                    let cell_html_attrs = this.build_html_attrs_fstr(cell_attrs);
                    // Adaptive label to left of slider
                    return html(`<div ${cell_html_attrs}><label style="margin-bottom: 0;">${cell}%</label><input ${image_buffer_slider_html_attrs}></div>`)
                },
                width: '200px',
            }
            this.column_names[this.column_map.SUBMISSION_DATE.col_idx] = {
                name: this.column_map.SUBMISSION_DATE.display_name,
                sort: {
                    compare: this.sort_dates
                }
            }
            this.column_names[this.column_map.FIELD_PROGRESS.col_idx] = {
                name: this.column_map.FIELD_PROGRESS.display_name,
                formatter: (cell, row) => {
                    let [progress_div_attrs, cell_attrs] = this.get_base_table_attrs(cell, row, this.column_map.FIELD_PROGRESS);
                    progress_div_attrs['value'] = cell;

                    // Split cell approved/rejected/total
                    let cell_split = cell.split('/');
                    let approved = cell_split[0];
                    let rejected = cell_split[1];
                    let total = cell_split[2];

                    // Cast to float
                    approved = parseFloat(approved);
                    rejected = parseFloat(rejected);
                    total = parseFloat(total);
                    let approved_pct = (approved/total) * 100;
                    let done_pct = ((approved + rejected)/total) * 100;
                    
                    progress_div_attrs['style'] = `
                        background-image:
                            linear-gradient(green, green),
                            linear-gradient(red, red),
                            linear-gradient(white, white);
                        background-size: ${approved_pct}% 100%, ${done_pct}% 100%, 100% 100%;
                        background-repeat: no-repeat;
                        width: 95%;
                        height: 40%;
                        border-radius: 15px;
                        border: 1px solid black;
                    `;

                    // Insert cell_attributes into html
                    let progress_div_html_attrs = this.build_html_attrs_fstr(progress_div_attrs);
                    let cell_html_attrs = this.build_html_attrs_fstr(cell_attrs);
                    // Progress hover tooltip
                    let progress_tooltip = `Approved: ${approved} Rejected: ${rejected} Total: ${total}`
                    return html(`<div ${cell_html_attrs}><div ${progress_div_html_attrs} title="${progress_tooltip}"></div></div>`)
                },
                sort: {
                    compare: (a, b) => {
                        if (a == b) {  // TODO also sort on rejected?
                            return 0;
                        }
                        let a_split = a.split('/');
                        let b_split = b.split('/');
                        let a_approved = parseFloat(a_split[0]);
                        let a_rejected = parseFloat(a_split[1]);
                        let a_total = parseFloat(a_split[2]);
                        let b_approved = parseFloat(b_split[0]);
                        let b_rejected = parseFloat(b_split[1]);
                        let b_total = parseFloat(b_split[2]);
                        let a_pct = (a_approved + a_rejected)/a_total;
                        let b_pct = (b_approved + b_rejected)/b_total;
                        if (a_pct > b_pct) {
                            return -1;
                        }
                        return 1;
                    }
                }
            }
            this.column_names[this.column_map.KEYPOINT_CONFIDENCE_SLIDER_DEFAULT_VALUE.col_idx] = {
                name: this.column_map.KEYPOINT_CONFIDENCE_SLIDER_DEFAULT_VALUE.display_name,
                formatter: (cell, row) => {
                    let [keypoint_confidence_slider_attrs, cell_attrs] = this.get_base_table_attrs(cell, row, this.column_map.KEYPOINT_CONFIDENCE_SLIDER_DEFAULT_VALUE);
                    // Keypoint confidence slider from 0 to 100
                    keypoint_confidence_slider_attrs = {
                        ...keypoint_confidence_slider_attrs,
                        'type': 'range',
                        'min': 0,
                        'max': 100,
                        'value': cell,
                        'onchange': 'update_keypoint_confidence(this)',
                        'oninput': 'update_keypoint_confidence(this)'
                    }
                    cell_attrs['style'] += 'display: flex;  justify-content: space-between; width: 100%; padding: 0.7rem; align-items: center;'
                    // Insert attrs into html
                    let keypoint_confidence_slider_html_attrs = this.build_html_attrs_fstr(keypoint_confidence_slider_attrs);
                    let cell_html_attrs = this.build_html_attrs_fstr(cell_attrs);
                    return html(`<div ${cell_html_attrs}><label style="margin-bottom: 0;">${cell}%</label><input ${keypoint_confidence_slider_html_attrs}></div>`)
                },
                width: '200px',
            }
            this.column_names[this.column_map.ROW_DISTANCE_FILTER_STATUS.col_idx] = {
                name: this.column_map.ROW_DISTANCE_FILTER_STATUS.display_name,
                formatter: (cell, row) => {
                    let [checkbox_attrs, cell_attrs] = this.get_base_table_attrs(cell, row, this.column_map.ROW_DISTANCE_FILTER_STATUS);
                    // Copy checkbox_attrs to sliderbar_attrs
                    let sliderbar_attrs = {...checkbox_attrs};
                    checkbox_attrs['onclick'] = 'toggle_row_distance_filter(this)';
                    cell_attrs['style'] += 'display: flex; flex-direction: column; justify-content: center; width: 100%;'

                    let [filter_active, filter_value] = cell.split('#');
                    filter_value = parseFloat(filter_value);
                    sliderbar_attrs = {
                        ...sliderbar_attrs,
                        'type': 'range',
                        'min': 0,
                        'max': 400,
                        'value': filter_value,
                        'onchange': 'update_row_distance_filter_value(this)',
                        'oninput': 'update_row_distance_filter_value(this)'
                    }
                    let slider_and_label_div_attrs = {
                        'style': 'justify-content: space-between; width: 100%; align-items: center; padding-top: 0.5rem;'
                    }
                    let checkbox_bool = filter_active == this.CHECKBOX_VALS.CHECKED;
                    if (checkbox_bool) {
                        checkbox_attrs['checked'] = true;
                        sliderbar_attrs['style'] = '';
                        slider_and_label_div_attrs['style'] += 'display: flex;';
                    }
                    else {
                        slider_and_label_div_attrs['style'] += 'display: none;';
                    }

                    let label_attrs = {
                        'style': 'margin-bottom: 0; padding-right: 0.4rem; padding-left: 0.4rem;'
                    }
                    // Insert attrs into html
                    let checkbox_html_attrs = this.build_html_attrs_fstr(checkbox_attrs);
                    let sliderbar_html_attrs = this.build_html_attrs_fstr(sliderbar_attrs);
                    let slider_and_label_div_html_attrs = this.build_html_attrs_fstr(slider_and_label_div_attrs);
                    let label_html_attrs = this.build_html_attrs_fstr(label_attrs);
                    let cell_html_attrs = this.build_html_attrs_fstr(cell_attrs);
                    return html(`<div ${cell_html_attrs}><input type="checkbox" ${checkbox_html_attrs}><div ${slider_and_label_div_html_attrs}><label ${label_html_attrs}>${filter_value}px</label><input ${sliderbar_html_attrs}></div></div>`)
                },
                width: '200px',
            };
            /*
            Editable columns have their initial values stored in og_values
            og_values is a dictionary of dictionaries, where the first key is the column name
            and the second key is the ddb_field_name, e.g. the second item in the row
             
             */
            let editable_cols = [];
            for (let col in this.column_map) {
                if (this.column_map[col].editable) {
                    editable_cols.push(this.column_map[col]);
                }
            }

            // Copy all editable columns to og_values
            for (let data_row of field_data.data.data) {
                // Iterate through editable_col_keys and values
                for (let col in editable_cols) {
                    let col_idx = editable_cols[col].col_idx;
                    let col_name = editable_cols[col].display_name;
                    if (!(col_name in this.og_values)) {
                        this.og_values[col_name] = {};
                    }
                    this.set_og_value(data_row, col_name, data_row[col_idx])
                }
            }
            // Sort data_rows by Submission Date
            let sort_col_idx = this.column_map.SUBMISSION_DATE.col_idx;
            this.data_rows = field_data.data.data.sort((a, b) => {
                return this.sort_dates(a[sort_col_idx], b[sort_col_idx])
            });
        },
        clear_grid_cache() {
            /*
            This one took an hour or so of head-banging to figure out.
            Every internal storage points back to the original data_rows array.
            This cache gets maintained by the Grid component, so we need to clear it to make anything work.
            Any other edits will persist in the objects, but not be displayed correctly unless this is cleared.
            */
            this.grid.config.pipeline.clearCache();
        },
        get_og_key(data_row) {
            return `${data_row[this.column_map.FIELD_GROUP.col_idx]}#${data_row[this.column_map.FIELD_NAME.col_idx]}`;
        },
        get_og_value(data_row, col_display_name) {
            let og_key = this.get_og_key(data_row);
            return this.og_values[col_display_name][og_key];
        },
        set_og_value(data_row, col_display_name, value) {
            let og_key = this.get_og_key(data_row);
            this.og_values[col_display_name][og_key] = value;
        },
        toggle_outsourced(checkbox) {
            let data_row = this.get_data_row_from_input(checkbox);
            let [previous_outsourced_val, outsourced_priority] = data_row[this.column_map.OUTSOURCED.col_idx].split('#');
            if (previous_outsourced_val == this.OUTSOURCED_VALS.YES) { 
                data_row[this.column_map.OUTSOURCED.col_idx] = this.OUTSOURCED_VALS.NO + '#' + outsourced_priority;
                checkbox.parentElement.children[1].style.display = 'none';
            }
            else { 
                data_row[this.column_map.OUTSOURCED.col_idx] = this.OUTSOURCED_VALS.YES + '#' + outsourced_priority;
                checkbox.parentElement.children[1].style.display = '';
            }
            this.clear_grid_cache();

            // Highlight if changed
            this.highlight_if_changed(
                checkbox.parentElement,
                data_row,
                this.column_map.OUTSOURCED
            )
        },
        change_outsourcing_priority(slider) { 
            let data_row = this.get_data_row_from_input(slider);
            let outsourced_priority = slider.value;
            let outsourced_value = data_row[this.column_map.OUTSOURCED.col_idx].split('#')[0];
            data_row[this.column_map.OUTSOURCED.col_idx] = outsourced_value + '#' + outsourced_priority;
            this.clear_grid_cache();

            // Highlight if changed
            this.highlight_if_changed(
                slider.parentElement,
                data_row,
                this.column_map.OUTSOURCED
            )
        },
        update_due_date(date_selector) {
            let data_row = this.get_data_row_from_input(date_selector)
            data_row[this.column_map.DUE_DATE.col_idx] = date_selector.value;

            this.clear_grid_cache();

            // Highlight if changed
            this.highlight_if_changed(
                date_selector.parentElement,
                data_row,
                this.column_map.DUE_DATE
            )
        },
        update_billed_date(month_selector) {
            let data_row = this.get_data_row_from_input(month_selector)
            data_row[this.column_map.BILLED_DATE.col_idx] = month_selector.value;

            this.clear_grid_cache();
            this.highlight_if_changed(
                month_selector.parentElement,
                data_row,
                this.column_map.BILLED_DATE
            )
        },
        update_image_buffer(image_buffer_slider) { 
            let data_row = this.get_data_row_from_input(image_buffer_slider)
            data_row[this.column_map.IMAGE_BUFFER.col_idx] = image_buffer_slider.value;
            let buffer_label = $(image_buffer_slider).siblings('label')[0];
            buffer_label.innerHTML = image_buffer_slider.value + '%';

            this.clear_grid_cache();
            this.highlight_if_changed(
                image_buffer_slider.parentElement,
                data_row,
                this.column_map.IMAGE_BUFFER
            )
        },
        update_keypoint_confidence(keypoint_confidence_slider) {
            let data_row = this.get_data_row_from_input(keypoint_confidence_slider)
            data_row[this.column_map.KEYPOINT_CONFIDENCE_SLIDER_DEFAULT_VALUE.col_idx] = keypoint_confidence_slider.value;
            let conf_label = $(keypoint_confidence_slider).siblings('label')[0];
            conf_label.innerHTML = keypoint_confidence_slider.value + '%';

            this.clear_grid_cache();
            this.highlight_if_changed(
                keypoint_confidence_slider.parentElement,
                data_row,
                this.column_map.KEYPOINT_CONFIDENCE_SLIDER_DEFAULT_VALUE
            )
        },
        toggle_row_distance_filter(row_distance_checkbox) {
            let data_row = this.get_data_row_from_input(row_distance_checkbox);
            let [previous_row_distance_filter_status, row_distance_filter_status_priority] = data_row[this.column_map.ROW_DISTANCE_FILTER_STATUS.col_idx].split('#');
            if (previous_row_distance_filter_status == this.CHECKBOX_VALS.CHECKED) {
                data_row[this.column_map.ROW_DISTANCE_FILTER_STATUS.col_idx] = this.CHECKBOX_VALS.UNCHECKED + '#' + row_distance_filter_status_priority;
                $(row_distance_checkbox).siblings('div')[0].style.display = 'none';
            }
            else {
                data_row[this.column_map.ROW_DISTANCE_FILTER_STATUS.col_idx] = this.CHECKBOX_VALS.CHECKED + '#' + row_distance_filter_status_priority;
                $(row_distance_checkbox).siblings('div')[0].style.display = 'flex';
            }
            this.clear_grid_cache();

            this.highlight_if_changed(
                row_distance_checkbox.parentElement,
                data_row,
                this.column_map.ROW_DISTANCE_FILTER_STATUS
            )
        },
        update_row_distance_filter_value(row_distance_slider) {
            let data_row = this.get_data_row_from_input(row_distance_slider)
            let row_distance_filter_status = data_row[this.column_map.ROW_DISTANCE_FILTER_STATUS.col_idx].split('#')[0];
            let new_filter_value = row_distance_slider.value;
            data_row[this.column_map.ROW_DISTANCE_FILTER_STATUS.col_idx] = row_distance_filter_status + '#' + new_filter_value;

            let conf_label = $(row_distance_slider).siblings('label')[0];
            conf_label.innerHTML = new_filter_value + 'px';

            this.clear_grid_cache();
            this.highlight_if_changed(
                row_distance_slider.parentElement.parentElement,
                data_row,
                this.column_map.ROW_DISTANCE_FILTER_STATUS
            )
        },
        save_changes() {
            // Compare data_rows to og_values
            for (let cur_data_row of this.data_rows) {
                // If this slows, pull out shared calculations to be per-row
                for (const [, col_map_item] of Object.entries(this.column_map)) {
                    if (col_map_item.ddb_tags.length > 0) {
                        this.save_input_changes_to_ddb(cur_data_row, col_map_item)
                    }
                }
            }
            // These will timeout, but we don't care
            hit_terraform_api({}, "gen_all_field_table_data");
            hit_terraform_api({}, "gen_all_outsourced_fields");
        },
    },
}

</script>

<style>
/* td.gridjs-td gets overwritten and doesn't accept the config style correctly*/
td {
    position: relative;
}

/* TODO make a default class and have these for other changes ?? */
div.due-date-cell, div.billed-date-cell, div.outsourced-cell, div.field-progress-cell, div.image-buffer-cell {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
input.billed-date-input { 
    width: 95%;
}
input.outsourcing-priority-slider { 
    margin-top: 5px;
}


td>span {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
}

div.save-changes {
    position: absolute;
    z-index: 100;
    width: fit-content;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

</style>
